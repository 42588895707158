import { defineStore } from 'pinia'

import { getComplaintSourceList } from '@/api/system/complaintSource'

import type { ComplaintSourceItem, ComplaintSourceParams } from '@/types/system/complaintSource'

export const SystemComplaintSource = defineStore('SystemComplaintSource', {
  state: () => {
    return {
      list: [] as Array<ComplaintSourceItem>
    }
  },
  getters: {
    ComplaintSourceList: state => state.list as Array<ComplaintSourceItem>
  },
  actions: {
    async getList(ComplaintSourceParams: ComplaintSourceParams = { pageIndex: 1, pageSize: 100 }) {
      try {
        const res = await getComplaintSourceList(ComplaintSourceParams)
        const {
          data: { items },
          succeeded
        } = res
        succeeded ? (this.list = items) : ''
        return res
      } catch (error) {}
    }
  }
})
