import request from '@/utils/request'
import type { PageResultData, RequestResult } from '../typing'
import type { PaymentTypeItem, PaymentTypeParams } from '@/types/system/paymentType'

export async function getPaymentTypeList() {
  return request.get<null, RequestResult<Array<PaymentTypeItem>>>('/api/PaymentChannel/GetList')
}
export async function getPaymentTypePagedList(PaymentTypeParams: PaymentTypeParams) {
  return request.get<null, RequestResult<PageResultData<PaymentTypeItem>>>('/api/PaymentChannel/GetPagedList', { params: PaymentTypeParams })
}
