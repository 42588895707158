import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const System: RouteRecordRaw = {
  path: 'system',
  name: 'system',
  meta: { title: '系统设置', role: 'Admin.Config', icon: 'oa_peizhi_setting', fullPath: '/manage/system' },
  component: RouteView,
  redirect: '/manage/system/index',
  children: [
    {
      path: 'index',
      name: 'system-index',
      meta: { icon: 'oa_peizhi_setting', role: 'Admin.Config', hideInMenu: true, title: '系统设置', fullPath: '/manage/system/index' },
      component: (): Component => import('@/views/system/index.vue')
    },
    {
      path: 'organizationunit',
      name: 'system-organizationunit',
      meta: { icon: 'oa_org_setting', role: 'Admin.Config.Organization', title: '律所组织机构', fullPath: '/manage/system/organizationunit' },
      component: (): Component => import('@/views/system/organizationUnit/index.vue')
    },
    {
      path: 'lawfirm',
      name: 'system-lawfirm',
      meta: { icon: 'oa_lawfirm', role: 'Admin.Config.Lawfirm', title: '律所管理', fullPath: '/manage/system/lawfirm' },
      component: (): Component => import('@/views/system/lawfirm/index.vue')
    },
    {
      path: 'lawfirm',
      name: 'system-lawfirm-index',
      meta: { title: '律所管理', role: 'Admin.Config.Lawfirm', hideInMenu: true, hideChildrenInMenu: true, icon: 'oa_lawfirm', fullPath: '/manage/system/lawfirm/index' },
      component: RouteView,
      redirect: '/manage/system/lawfirm',
      children: [
        {
          path: 'config/:id(\\d+)/:title?',
          name: 'system-lawfirm-config',
          props: true,
          meta: { icon: 'oa_param', hideInMenu: true, title: '律所参数配置', fullPath: '/manage/system/lawfirm/config' },
          component: (): Component => import('@/views/system/lawfirm/config.vue')
        },
        {
          path: 'function/:id(\\d+)/:title?',
          name: 'system-lawfirm-function',
          props: true,
          meta: { icon: 'oa_setting', hideInMenu: true, title: '律所功能配置', fullPath: '/manage/system/lawfirm/function' },
          component: (): Component => import('@/views/system/lawfirm/function.vue')
        }
      ]
    },
    {
      path: 'system',
      name: 'system-system',
      meta: { icon: 'oa_setting', role: 'Admin.Config.SystemConfig', title: '系统参数配置', fullPath: '/manage/system/system' },
      component: (): Component => import('@/views/system/system/index.vue')
    },
    {
      path: 'dictionarymanage',
      name: 'system-dictionary-manage',
      meta: { icon: 'oa_sms', role: 'Admin.Config.Dictionary', hideInMenu: true, hideChildrenInMenu: true, title: '字典项管理', fullPath: '/manage/system/dictionarymanage' },
      component: (): Component => import('@/views/system/dictionary/index.vue')
    },
    {
      path: 'dictionarymanage',
      name: 'system-dictionarymanage-index',
      meta: { icon: 'oa_sms', role: 'Admin.Config.Dictionary', hideChildrenInMenu: true, title: '字典项管理', fullPath: '/manage/system/dictionarymanage' },
      component: RouteView,
      redirect: '/manage/system/dictionarymanage',
      children: [
        ////////////////  人员相关
        {
          path: 'usertype',
          name: 'system-dictionarymanage-usertype',
          meta: { icon: 'oa_leibie', hideInMenu: true, title: '人员类别', fullPath: '/manage/system/dictionarymanage/usertype' },
          component: (): Component => import('@/views/system/userType/index.vue')
        },
        {
          path: 'position',
          name: 'system-dictionarymanage-position',
          meta: { icon: 'oa_zhiwu', hideInMenu: true, title: '职务管理', fullPath: '/manage/system/dictionarymanage/position' },
          component: (): Component => import('@/views/system/position/index.vue')
        },

        ////////////////  来源相关
        {
          path: 'clientSource',
          name: 'system-dictionarymanage-clientSource',
          meta: { icon: 'oa_client_source', hideInMenu: true, title: '客户来源管理', fullPath: '/manage/system/dictionarymanage/clientsource' },
          component: (): Component => import('@/views/system/clientSource/index.vue')
        },
        {
          path: 'contractSource',
          name: 'system-dictionarymanage-contractSource',
          meta: { icon: 'oa_laiyuan', hideInMenu: true, title: '案件来源管理', fullPath: '/manage/system/dictionarymanage/contractsource' },
          component: (): Component => import('@/views/system/contractSource/index.vue')
        },
        {
          path: 'projectSource',
          name: 'system-dictionarymanage-projectSource',
          meta: { icon: 'oa_laiyuan', hideInMenu: true, title: '项目来源管理', fullPath: '/manage/system/dictionarymanage/projectSource' },
          component: (): Component => import('@/views/system/projectSource/index.vue')
        },
        {
          path: 'complaintSource',
          name: 'system-dictionarymanage-complaintSource',
          meta: { icon: 'oa_tousu', hideInMenu: true, title: '投诉来源管理', fullPath: '/manage/system/dictionarymanage/complaintsource' },
          component: (): Component => import('@/views/system/complaintSource/index.vue')
        },
        {
          path: 'demandSource',
          name: 'system-dictionarymanage-demandSource',
          meta: { icon: 'oa_xiansuo', hideInMenu: true, title: '线索来源管理', fullPath: '/manage/system/dictionarymanage/demandSource' },
          component: (): Component => import('@/views/system/demandSource/index.vue')
        },
        //////////// 客户相关

        {
          path: 'clientType',
          name: 'system-dictionarymanage-clientType',
          meta: { icon: 'oa_client_type', hideInMenu: true, title: '客户类别管理', fullPath: '/manage/system/dictionarymanage/clienttype' },
          component: (): Component => import('@/views/system/clientType/index.vue')
        },
        {
          path: 'idCardType',
          name: 'system-dictionarymanage-idCardType',
          meta: { icon: 'oa_zhengjian', hideInMenu: true, title: '证件类型管理', fullPath: '/manage/system/dictionarymanage/idcardtype' },
          component: (): Component => import('@/views/system/idCardType/index.vue')
        },
        ////////////// 财务相关
        {
          path: 'paymentMethod',
          name: 'system-dictionarymanage-paymentMethod',
          meta: { icon: 'oa_pay', hideInMenu: true, title: '收费方式管理', fullPath: '/manage/system/dictionarymanage/paymentmethod' },
          component: (): Component => import('@/views/system/paymentMethod/index.vue')
        },
        {
          path: 'paymentChannel',
          name: 'system-dictionarymanage-paymentChannel',
          meta: { icon: 'oa_pay', hideInMenu: true, title: '缴费渠道管理', fullPath: '/manage/system/dictionarymanage/paymentchannel' },
          component: (): Component => import('@/views/system/paymentChannel/index.vue')
        },
        {
          path: 'invoiceType',
          name: 'system-dictionarymanage-invoiceType',
          meta: { icon: 'oa_invoice', hideInMenu: true, title: '发票类型管理', fullPath: '/manage/system/dictionarymanage/invoicetype' },
          component: (): Component => import('@/views/system/invoiceType/index.vue')
        },

        ///////////////  案件相关数据
        {
          path: 'contractType',
          name: 'system-dictionarymanage-contractType',
          meta: { icon: 'oa_leibie', hideInMenu: true, title: '案件类型管理', fullPath: '/manage/system/dictionarymanage/contracttype' },
          component: (): Component => import('@/views/system/contractType/index.vue')
        },
        {
          path: 'casereason',
          name: 'system-dictionarymanage-casereason',
          meta: { icon: 'oa_court', hideInMenu: true, title: '案由管理', fullPath: '/manage/system/dictionarymanage/casereason' },
          component: (): Component => import('@/views/system/caseReason/index.vue')
        },
        {
          path: 'triallevel',
          name: 'system-dictionarymanage-triallevel',
          meta: { icon: 'oa_books', hideInMenu: true, title: '审级管理', fullPath: '/manage/system/dictionarymanage/triallevel' },
          component: (): Component => import('@/views/system/trialLevel/index.vue')
        },
        {
          path: 'projectContractType',
          name: 'system-dictionarymanage-projectContractType',
          meta: { icon: 'oa_leibie', hideInMenu: true, title: '一般项目类型管理', fullPath: '/manage/system/dictionarymanage/projectNormaltype' },
          component: (): Component => import('@/views/system/projectNormalType/index.vue')
        },
        ///////////////  基础数据
        {
          path: 'region',
          name: 'system-dictionarymanage-region',
          meta: { icon: 'oa_map', hideInMenu: true, title: '地区管理', fullPath: '/manage/system/dictionarymanage/region' },
          component: (): Component => import('@/views/system/region/index.vue')
        },
        {
          path: 'businessArea',
          name: 'system-dictionarymanage-businessArea',
          meta: { icon: 'oa_court', hideInMenu: true, title: '领域管理', fullPath: '/manage/system/dictionarymanage/businessarea' },
          component: (): Component => import('@/views/system/businessArea/index.vue')
        },
        {
          path: 'industry',
          name: 'system-dictionarymanage-industry',
          meta: { icon: 'oa_hangye', hideInMenu: true, title: '行业管理', fullPath: '/manage/system/dictionarymanage/industry' },
          component: (): Component => import('@/views/system/industry/index.vue')
        },
        {
          path: 'organization',
          name: 'system-dictionarymanage-organization',
          meta: { icon: 'oa_court', hideInMenu: true, title: '受理机构', fullPath: '/manage/system/dictionarymanage/organization' },
          component: (): Component => import('@/views/system/organization/index.vue')
        },
        {
          path: 'filedir',
          name: 'system-dictionarymanage-filedir',
          meta: { icon: 'oa_file', hideInMenu: true, title: '文件类型目录管理', fullPath: '/manage/system/dictionarymanage/filedir' },
          component: (): Component => import('@/views/system/fileDir/index.vue')
        },
        {
          path: 'projectfiledir',
          name: 'system-dictionarymanage-projectfiledir',
          meta: { icon: 'oa_file', hideInMenu: true, title: '项目文件类型目录管理', fullPath: '/manage/system/dictionarymanage/projectfiledir' },
          component: (): Component => import('@/views/system/projectFileDir/index.vue')
        }
      ]
    },
    {
      path: 'smsTemplate',
      name: 'system-smsTemplate',
      meta: { icon: 'oa_sms', role: 'Admin.Config.SmsTemplate', title: '短信模板管理', fullPath: '/manage/system/smsTemplate' },
      component: (): Component => import('@/views/system/sms/template.vue')
    },
    {
      path: 'dataindex',
      name: 'system-dataindex',
      meta: { icon: 'oa_dataindex', role: 'Admin.Config.Index', title: '数据索引管理', fullPath: '/manage/system/dataindex' },
      component: (): Component => import('@/views/system/dataIndex/index.vue')
    }
  ]
}
