import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Finance: RouteRecordRaw = {
  path: 'financeproject',
  name: 'manage-financeproject',
  meta: { title: '财务管理(项目)', role: 'Admin.Finance', icon: 'oa_zhang', fullPath: '/manage/financeproject' },
  component: RouteView,
  redirect: '/manage/financeproject/project',
  children: [
    {
      path: 'project',
      name: 'manage-financeproject-project',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Manage', title: '财务管理', fullPath: '/manage/financeproject/project' },
      component: (): Component => import('@/views/manage/projectFinance/project/index.vue')
    },
    {
      path: 'info/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-financeproject-info',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Show', hideInMenu: true, title: '项目详情', fullPath: '/manage/financeproject/projectInfo' },
      component: (): Component => import('@/views/manage/projectFinance/info.vue')
    },
    // 开票管理
    {
      path: 'invoice',
      name: 'manage-financeproject-invoice',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Invoice', title: '发票管理', fullPath: '/manage/financeproject/invoice' },
      component: (): Component => import('@/views/manage/projectFinance/invoice/index.vue')
    },
    //添加发票
    {
      path: 'invoice/create/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-financeproject-invoice-create',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Invoice', hideInMenu: true, title: '添加发票', fullPath: '/manage/financeproject/invoice/create' },
      component: (): Component => import('@/views/manage/projectFinance/project/invoiceCreate.vue')
    },
    {
      path: 'invoice/update/:projectId(\\d+)/:projectTypeCode/:invoiceid?',
      name: 'manage-financeproject-invoice-update',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Invoice', hideInMenu: true, title: '编辑开票', fullPath: '/manage/financeproject/invoice/update' },
      component: (): Component => import('@/views/manage/projectFinance/invoice/update.vue')
    },
    // 缴费管理
    {
      path: 'payment',
      name: 'manage-financeproject-payment',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Payment', title: '收费管理', fullPath: '/manage/financeproject/payment' },
      component: (): Component => import('@/views/manage/projectFinance/payment/index.vue')
    },
    //添加缴费记录
    {
      path: 'payment/create/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-financeproject-payment-create',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Payment', hideInMenu: true, title: '添加收款记录', fullPath: '/manage/financeproject/payment/create' },
      component: (): Component => import('@/views/manage/projectFinance/project/paymentCreate.vue')
    },
    {
      path: 'payment/update/:projectId(\\d+)/:projectTypeCode/:paymentId(\\d+)',
      name: 'manage-financeproject-payment-update',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Payment', hideInMenu: true, title: '编辑缴费', fullPath: '/manage/financeproject/payment/update' },
      component: (): Component => import('@/views/manage/projectFinance/payment/update.vue')
    },
    // 退款
    {
      path: 'refund',
      name: 'manage-financeproject-refund',
      meta: { icon: 'oa_refund', role: 'Admin.Finance.Refund', title: '退款管理', fullPath: '/manage/financeproject/refund' },
      component: (): Component => import('@/views/manage/projectFinance/refund/index.vue')
    },
    //添加退款记录
    {
      path: 'refund/create/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-financeproject-refund-create',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Refund', hideInMenu: true, title: '添加退款记录', fullPath: '/manage/financeproject/refund/create' },
      component: (): Component => import('@/views/manage/projectFinance/project/refundCreate.vue')
    },
    {
      path: 'refund/update/:projectId(\\d+)/:projectTypeCode/:refundId(\\d+)',
      name: 'manage-financeproject-refund-update',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Refund', hideInMenu: true, title: '编辑退款', fullPath: '/manage/financeproject/refund/update' },
      component: (): Component => import('@/views/manage/projectFinance/refund/update.vue')
    },
    // 台账
    {
      path: 'ledger',
      name: 'manage-financeproject-ledger',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Account', title: '台账列表', fullPath: '/manage/financeproject/ledger' },
      component: (): Component => import('@/views/manage/projectFinance/ledger/index.vue')
    },
    {
      path: 'ledger/info/:userId(\\d+)',
      name: 'manage-financeproject-ledger-info',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Account', hideInMenu: true, title: '台账详情', fullPath: '/manage/financeproject/ledger/info' },
      component: (): Component => import('@/views/manage/projectFinance/ledger/info.vue')
    },
    // 预存
    {
      path: 'prestore',
      name: 'manage-financeproject-prestore',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.PrestoredInvoice', title: '预存发票', fullPath: '/manage/financeproject/prestore' },
      component: (): Component => import('@/views/manage/projectFinance/prestore/index.vue')
    },
    {
      path: 'prestore/info/:id(\\d+)',
      name: 'manage-financeproject-prestore-info',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.PrestoredInvoice', hideInMenu: true, title: '预存发票详情', fullPath: '/manage/financeproject/prestore/info' },
      component: (): Component => import('@/views/manage/projectFinance/prestore/info.vue')
    },
    // 提款
    {
      path: 'draw',
      name: 'manage-financeproject-draw',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Draw', title: '提款管理', fullPath: '/manage/financeproject/draw' },
      component: (): Component => import('@/views/manage/projectFinance/draw/index.vue')
    },

    {
      path: 'draw/Info/:userId(\\d+)',
      name: 'manage-financeproject-draw-info',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Draw', hideInMenu: true, title: '提款详情', fullPath: '/manage/financeproject/draw/info' },
      component: (): Component => import('@/views/manage/projectFinance/draw/info.vue')
    },
    // 借款
    {
      path: 'loan',
      name: 'manage-financeproject-loan',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Loan', title: '借款管理', fullPath: '/manage/financeproject/loan' },
      component: (): Component => import('@/views/manage/projectFinance/loan/index.vue')
    },
    {
      path: 'loan/info/:id(\\d+)',
      name: 'manage-financeproject-loan-info',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Loan', hideInMenu: true, title: '借款详情', fullPath: '/manage/financeproject/loan/info' },
      component: (): Component => import('@/views/manage/projectFinance/loan/info.vue')
    },
    // 调账
    {
      path: 'transfer',
      name: 'manage-financeproject-transfer',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Transfer', title: '调账管理', fullPath: '/manage/financeproject/transfer' },
      component: (): Component => import('@/views/manage/projectFinance/transfer/index.vue')
    },
    // 补充历史记录
    {
      path: 'supplement',
      name: 'manage-financeproject-supplement',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Manage', title: '补充历史记录', fullPath: '/manage/financeproject/supplement' },
      component: (): Component => import('@/views/manage/projectFinance/supplement/index.vue')
    },
    //补充开票退票记录
    {
      path: 'supplement/invoice/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-financeproject-supplement-invoice',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Invoice', hideInMenu: true, title: '补充开票退票记录', fullPath: '/manage/financeproject/supplement/invoice' },
      component: (): Component => import('@/views/manage/projectFinance/supplement/invoiceCreate.vue')
    },
    //编辑补充的开票退票记录
    {
      path: 'supplement/invoice/update/:projectId(\\d+)/:projectTypeCode/:invoiceId(\\d+)',
      name: 'manage-financeproject-supplement-invoice-update',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Invoice', hideInMenu: true, title: '编辑补充的开票退票记录', fullPath: '/manage/financeproject/supplement/invoiceupdate' },
      component: (): Component => import('@/views/manage/projectFinance/supplement/invoiceUpdate.vue')
    },
    //补充缴费退费记录
    {
      path: 'supplement/payment/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-financeproject-supplement-payment',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Payment', hideInMenu: true, title: '补充缴费退费记录', fullPath: '/manage/financeproject/supplement/payment' },
      component: (): Component => import('@/views/manage/projectFinance/supplement/paymentCreate.vue')
    },
    //编辑补充的缴费退费记录
    {
      path: 'supplement/payment/update/:projectId(\\d+)/:projectTypeCode/:paymentId(\\d+)',
      name: 'manage-financeproject-supplement-payment-update',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Payment', hideInMenu: true, title: '编辑补充的缴费退费记录', fullPath: '/manage/financeproject/supplement/paymentupdate' },
      component: (): Component => import('@/views/manage/projectFinance/supplement/paymentUpdate.vue')
    }
  ]
}
