import request from '@/utils/request'
import type { PageResultData, RequestResult } from '../typing'
import type { CheckExist } from '@/types/typing'
import type { ClientTypeCreateOrUpdate, ClientTypeItem, ClientTypeParams } from '@/types/system/clientType'

/**
 * 客户类型
 * 获取客户类型列表
 * @param {ClientTypeParams} ClientTypeParams
 * @returns {PageResultData<ClientTypeItem>}
 */
export async function getClientTypeList(ClientTypeParams: ClientTypeParams) {
  return request.get<ClientTypeParams, RequestResult<PageResultData<ClientTypeItem>>>('/api/ClientType/GetPagedList', { params: ClientTypeParams })
}

/**
 * 根据ID获取客户类型详情
 * @param {number} id
 * @returns {RequestResult<ClientTypeItem>}
 */
export async function getClientTypeInfo(id: number) {
  return request.get<number, RequestResult<ClientTypeItem>>(`/api/ClientType/GetById/${id}`)
}

/**
 * 检查是否重复
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<ClientTypeItem>}
 */
export async function checkClientTypeName(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/ClientType/CheckExist`, CheckExist)
}

/**
 * 创建客户类型
 * @param {ClientTypeCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function createClientType(data: ClientTypeCreateOrUpdate) {
  return request.post<ClientTypeCreateOrUpdate, RequestResult<null>>(`/api/ClientType/create`, data)
}

/**
 * 编辑客户类型
 * @param {ClientTypeCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function updateClientType(data: ClientTypeCreateOrUpdate) {
  return request.put<ClientTypeCreateOrUpdate, RequestResult<null>>(`/api/ClientType/update`, data)
}

/**
 * 删除客户类型
 * @param {number} data
 * @returns {RequestResult}
 */
export async function deleteClientType(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/ClientType/delete/${id}`)
}

/**
 * 软删除客户类型
 * @param {number} data
 * @returns {RequestResult}
 */
export async function deleteClientTypeSoft(id: number) {
  return request.post<number, RequestResult<null>>(`/api/ClientType/SoftDelete/${id}`)
}
