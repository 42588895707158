---

# props

| 属性 | 说明 | 类型 | 默认值 |
| --- | --- | --- | --- |
| title | 标题 | string | *(必填项) |

# slot

| 属性 | 说明 | 类型 | 默认值 |
| --- | --- | --- | --- |
| title | 标题 | slot | props.title |
| rightExtra | 右侧扩展功能区 | slot |  |
| content | 内容区域 | slot |  |

---

<template>
  <a-tabs type="card" class="card_form">
    <a-tab-pane :key="1" class="card_form_content">
      <template #tab>
        <template v-if="$slots.title">
          <slot name="title" />
        </template>
        <template v-else>
          {{ props.title }}
        </template>
      </template>
      <slot name="content" />
    </a-tab-pane>
    <template #rightExtra>
      <slot name="rightExtra" />
    </template>
  </a-tabs>
</template>

<script setup lang="ts">
  defineOptions({ name: 'InfoCardForm' })
  const props = defineProps({
    title: {
      type: String,
      require: true
    }
  })
</script>
<style lang="less" scoped>
  .ant-tabs.card_form {
    & + & {
      margin-top: 30px;
    }

    & > :deep(.ant-tabs-nav) {
      margin-bottom: 0;

      .ant-tabs-tab {
        font-size: 18px;
      }
    }

    :deep(.card_form_content) {
      padding: 30px 50px;
      background-color: #fff;
    }

    :deep(.ant-tabs-extra-content) {
      padding: 8px 16px;
      font-size: 18px;

      & > :hover {
        cursor: pointer;
      }

      .ant-btn {
        height: auto;
        padding: 0;
        font-size: 18px;
        line-height: 1;

        & + .ant-btn {
          margin-left: 10px;
        }
      }
    }
  }
</style>
<style lang="less"></style>
