import { defineStore } from 'pinia'

import { getClientSourceListAll } from '@/api/system/clientSource'

import type { ClientSourceItem } from '@/types/system/clientSource'

export const SystemClientSource = defineStore('SystemClientSource', {
  state: () => {
    return {
      list: [] as Array<ClientSourceItem>
    }
  },
  getters: {
    clientSourceList: state => state.list as Array<ClientSourceItem>
  },
  actions: {
    async getList() {
      try {
        const res = await getClientSourceListAll()
        const { data, succeeded } = res
        succeeded ? (this.list = data) : ''
        return res
      } catch (error) {}
    }
  }
})
