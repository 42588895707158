import request from '@/utils/request'
import type { RequestResult, QueryParams } from '../typing'
import type { CheckExist } from '@/types/typing'
import type { PaymentMethodCreateOrUpdate, PaymentMethodItem } from '@/types/system/paymentMethod'

export type PaymentMethodParams = {
  name: string
} & QueryParams

/**
 * 获取收费方式列表
 * @param { Array<number>} idList
 * @returns {PageResultData<PaymentMethodItem>}
 */
export async function getPaymentMethodAllTree(idList: Array<number>) {
  return request.get<PaymentMethodParams, RequestResult<Array<PaymentMethodItem>>>('/api/PaymentMethod/GetTreeList', { params: idList })
}

/**
 * 获取 指定节点 tree
 * @param {number} id
 * @returns {RequestResult<PaymentMethodItem>}
 */
export async function getPaymentMethodNodeTree(id: number) {
  return request.get<number, RequestResult<PaymentMethodItem>>(`/api/PaymentMethod/GetTree/${id}`)
}
/**
 * 获取节点ID下子节点
 * @param {number} id
 * @returns {RequestResult<Array<PaymentMethodItem>>}
 */
export async function getPaymentMethodChildTree(id: number) {
  return request.get<number, RequestResult<Array<PaymentMethodItem>>>(`/api/PaymentMethod/GetChildList/${id}`)
}

/**
 * 根据ID获取收费方式详情
 * @param {number} id
 * @returns {RequestResult<PaymentMethodItem>}
 */
export async function getPaymentMethodInfo(id: number) {
  return request.get<number, RequestResult<PaymentMethodItem>>(`/api/PaymentMethod/GetById/${id}`)
}

/**
 * 检查是否重复
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<PaymentMethodItem>}
 */
export async function checkPaymentMethodName(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/PaymentMethod/CheckExist`, CheckExist)
}

/**
 * 创建收费方式
 * @param {PaymentMethodCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function createPaymentMethod(data: PaymentMethodCreateOrUpdate) {
  return request.post<PaymentMethodCreateOrUpdate, RequestResult<null>>(`/api/PaymentMethod/create`, data)
}

/**
 * 编辑收费方式
 * @param {PaymentMethodCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function updatePaymentMethod(data: PaymentMethodCreateOrUpdate) {
  return request.put<PaymentMethodCreateOrUpdate, RequestResult<null>>(`/api/PaymentMethod/update`, data)
}

/**
 * 删除收费方式
 * @param {number} id
 * @returns {RequestResult}
 */
export async function deletePaymentMethod(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/PaymentMethod/delete/${id}`)
}

/**
 * 软删除收费方式
 * @param {number} id
 * @returns {RequestResult}
 */
export async function deletePaymentMethodSoft(id: number) {
  return request.post<number, RequestResult<null>>(`/api/PaymentMethod/softdelete/${id}`)
}
