<template>
  <div id="user-layout" :class="['user-layout-wrapper']">
    <div class="container">
      <div class="top">
        <div class="header">
          <a href="/">
            <img src="@/assets/logo.png" class="logo" alt="logo" />
            <span class="title">{{ title }}</span>
          </a>
        </div>
        <div class="desc">您在此系统进行的所有操作将全部留痕</div>
      </div>

      <router-view />

      <div class="footer">
        <div class="links">
          <a href="_self">帮助</a>
          <a href="_self">隐私</a>
          <a href="_self">条款</a>
        </div>
        <div class="copyright">Copyright &copy; JingShOnline</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, onUnmounted } from 'vue'
  import { title } from '@/config'

  defineOptions({ name: 'AccountLayout' })

  onMounted(() => {
    document.body.classList.add('userLayout')
  })
  onUnmounted(() => {
    document.body.classList.remove('userLayout')
  })
</script>

<style lang="less" scoped>
  #user-layout.user-layout-wrapper {
    height: 100%;

    &.mobile {
      .container {
        .main {
          width: 98%;
          max-width: 368px;
        }
      }
    }

    .container {
      position: relative;
      width: 100%;
      min-height: 100%;
      padding: 110px 0 144px;

      a {
        text-decoration: none;
      }

      .top {
        text-align: center;

        .header {
          height: 44px;
          line-height: 44px;

          .badge {
            position: absolute;
            display: inline-block;
            margin-top: -10px;
            margin-left: -12px;
            line-height: 1;
            vertical-align: middle;
            opacity: 0.8;
          }

          .logo {
            height: 44px;
            margin-right: 16px;
            vertical-align: top;
            border-style: none;
          }

          .title {
            position: relative;
            top: 2px;
            font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
            font-size: 33px;
            font-weight: 600;
            color: @colorText;
          }
        }

        .desc {
          margin-top: 12px;
          margin-bottom: 40px;
          font-size: 14px;
          color: @colorTextSecondary;
        }
      }

      .main {
        width: 368px;
        min-width: 260px;
        margin: 0 auto;
      }

      .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0 16px;
        margin: 48px 0 24px;
        text-align: center;

        .links {
          margin-bottom: 8px;
          font-size: 14px;

          a {
            color: @colorTextSecondary;
            transition: all 0.3s;

            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }

        .copyright {
          font-size: 14px;
          color: @colorTextSecondary;
        }
      }
    }
  }
</style>
