import { defineStore } from 'pinia'
import { RouteRecordRaw } from 'vue-router'

import router, { UserRoute, ManageRoute } from '@/router'

import { getPermissionUserRange, getUserPermissions } from '@/api/permission/permission'

import { enablePermission } from '@/config'

import type { PermissionInfo, PermissionItem, UserRangeItem } from './typing'

export const Permission = defineStore('Permission', {
  state: () => {
    return {
      list: [],
      role: [],
      allowRouters: [],
      allowUserRouters: [],
      allowManageRouters: []
    } as PermissionInfo
  },
  getters: {
    userRangeList: state => state.list as Array<UserRangeItem>,
    userPermission: state => state.role as Array<PermissionItem>,
    userRole: state => state.role.map(item => item.permissionCode) as Array<string>
  },
  actions: {
    async getUserRangeList() {
      try {
        const res = await getPermissionUserRange()
        const { data, succeeded } = res
        succeeded ? (this.list = data) : ''
        return res
      } catch (error) {}
    },
    async getUserPermission() {
      try {
        const res = await getUserPermissions()
        const { data, succeeded } = res
        succeeded ? (this.role = data) : ''
        return res
      } catch (error) {}
    },
    checkPermissionsCode(code: string) {
      return this.userRole.includes(code)
    },
    /**
     * 检查路由所需权限是否存在
     * @param { RouteRecordRaw[] } route
     * @returns { RouteRecordRaw[] }
     */
    checkRoute(route: Array<RouteRecordRaw>) {
      // 检查传入路由数据是否有权限
      // 过滤掉没有权限的路由
      route = route.filter(item => (item.meta?.role ? this.userRole.includes(item.meta.role) : true))
      // 递归检查子路由
      route.forEach(item => (item.children ? (item.children = this.checkRoute(item.children)) : ''))
      // 返回过滤后的路由数据
      return route
    },
    // 从路由表构建路由(前端对比后端权限字段过滤静态路由表)
    getAccountGenerateRoutes() {
      // 修改这里可以进行接口返回的对象结构进行改变
      // 亦或是去掉 info.role 使用别的属性替代
      // 任何方案都可以，只需要将最后拼接构建好的路由数组使用
      // router.addRoute() 添加到当前运行时的路由中即可
      // 添加到路由表

      /**
       * 用户相关路由
       */
      if (enablePermission && UserRoute.children) {
        UserRoute.children = this.checkRoute(UserRoute.children as Array<RouteRecordRaw>)
      }

      UserRoute.children ? (this.allowUserRouters = UserRoute.children) : ''
      router.addRoute(UserRoute)

      /**
       * 管理中心路由
       */
      if (enablePermission && ManageRoute.children) {
        ManageRoute.children = this.checkRoute(ManageRoute.children as Array<RouteRecordRaw>)
      }

      ManageRoute.children ? (this.allowManageRouters = ManageRoute.children) : ''
      router.addRoute(ManageRoute)
      // 合并路由
      this.allowRouters = [UserRoute, ManageRoute]

      return this.allowRouters
    },
    clear() {
      this.$reset()
    }
  }
})
