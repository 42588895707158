<template>
  <!-- <div :style="{ width: `${menuCollapsed ? sideBarCollapsedWidth : sideBarWidth}px`, overflow: 'hidden', flexShrink: 0 }" /> -->
  <a-layout-sider v-model:collapsed="menuCollapsed" breakpoint="lg" :collapsed-width="sideBarCollapsedWidth" :width="sideBarWidth">
    <div class="logo" :style="{ height: `${globalHeaderHeight}px`, lineHeight: `${globalHeaderHeight}px` }">
      <router-link :to="{ name: 'index' }">
        <img src="@/assets/logo.png" alt="logo" />
        <h1 v-if="!menuCollapsed">{{ title }}</h1>
      </router-link>
    </div>
    <div :style="{ flex: 1, overflow: 'hidden auto', height: `calc(100vh - ${globalHeaderHeight}px)` }">
      <menu-index :mode="props.mode" :menu="props.menu" />
    </div>
  </a-layout-sider>
</template>

<script setup lang="ts">
  import { MenuMode } from 'ant-design-vue/es/menu/src/interface'
  import { PropType } from 'vue'
  import { title } from '@/config'

  import MenuIndex from '../menu/index.vue'

  import { AppStores } from '@/stores/modules/app'
  import { storeToRefs } from 'pinia'
  import { RouteRecordRaw } from 'vue-router'
  defineOptions({ name: 'SideBarIndex' })
  const stores = AppStores()
  const { menuCollapsed, sideBarWidth, sideBarCollapsedWidth, globalHeaderHeight } = storeToRefs(stores)

  const props = defineProps({
    mode: {
      type: String as PropType<MenuMode>,
      default: 'inline'
    },
    menu: {
      type: Array as PropType<Array<RouteRecordRaw>>,
      require: true
    }
  })
</script>
<style lang="less" scoped>
  .ant-layout-has-sider {
    display: flex;
    flex-direction: row;
    transition: width 0.2s;
  }

  .ant-layout-sider {
    position: fixed;
    // height: 100%;
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100%;

    ::-webkit-scrollbar-thumb {
      background: rgb(255 255 255 / 30%);
    }
  }

  .logo {
    text-align: center;
    background: #001529;

    h1 {
      display: inline-block;
      height: 32px;
      margin: 0 0 0 12px;
      overflow: hidden;
      font-size: 18px;
      font-weight: 600;
      line-height: 32px;
      color: #fff;
      vertical-align: middle;
      animation: fade-in;
      animation-duration: 0.2s;
    }

    img {
      display: inline-block;
      width: 32px;
    }
  }
</style>
