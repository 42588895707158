import { defineStore } from 'pinia'
import { flattenObject } from '@/utils'

import { getContractTypeAllTree } from '@/api/system/contractType'

import type { ContractTypeItem } from '@/types/system/contractType'

export const SystemContractType = defineStore('SystemContractType', {
  state: () => {
    return {
      list: [] as Array<ContractTypeItem>
    }
  },
  getters: {
    contractTypeList: state => state.list as Array<ContractTypeItem>,
    // 根据传入项目分组(父节点ID)获取项目类型(子节点)
    getContractTypeChild: state => (id?: number) => {
      const child = id ? state.list.find(item => item.id === id) : null
      return child?.children
    },
    // * 根据传入项目类型 获取 项目对应审级
    getContractTypeTrialLevel: state => (id?: number) => {
      const listArray: Array<ContractTypeItem> = flattenObject(state.list)
      const name = listArray.find(item => item.id === id)?.name
      let trialLevel: Array<string> = []
      switch (name) {
        case '民事':
          trialLevel = ['民事一审', '民事二审', '民事再审', '民事重审', '申请执行']
          break
        case '破产管理人备案':
          trialLevel = ['民事一审', '民事二审', '民事再审', '民事重审', '申请执行']
          break
        case '刑事':
          trialLevel = ['侦查', '审查起诉', '申诉', '刑事一审', '刑事二审', '刑事再审', '刑事重审', '审判']
          break
        case '行政':
          trialLevel = ['行政一审', '行政二审', '行政再审', '行政重审', '申请执行']
          break
        case '执行':
          trialLevel = ['申请执行']
          break
        case '仲裁':
          trialLevel = ['仲裁', '反诉']
          break
        case '知识产权':
          trialLevel = ['民事一审', '民事二审', '民事再审', '民事重审', '申请执行']
          break
        case '其他案件':
          trialLevel = []
          break
        default:
          trialLevel = []
          break
      }
      return trialLevel
    },
    // 根据传入id & name 判断是否为指定 name 类型
    checkContractTypeIdIsSomeType: state => (id: number, name: string) => {
      const listArray: Array<ContractTypeItem> = flattenObject(state.list)
      return listArray.some(item => item.id === id && item.name === name)
    }
  },
  actions: {
    async getList() {
      try {
        const res = await getContractTypeAllTree()
        const { data, succeeded } = res
        succeeded ? (this.list = data) : ''
        return res
      } catch (error) {}
    }
  }
})
