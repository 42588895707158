import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Project: RouteRecordRaw = {
  path: 'project',
  name: 'manage-project',
  meta: { title: '项目管理', role: 'Admin.Case', icon: 'oa_yewuguanli', fullPath: '/manage/project' },
  component: RouteView,
  redirect: '/manage/project/index',
  children: [
    {
      path: 'check',
      name: 'manage-project-check',
      meta: { icon: 'oa_confirm', title: '项目预检', fullPath: '/manage/project/check' },
      component: (): Component => import('@/views/manage/project/check.vue')
    },
    {
      path: 'info/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-project-info',
      meta: { icon: 'oa_lawfirm', role: 'Admin.Case.Manage.Show', hideInMenu: true, title: '项目详情', fullPath: '/manage/project/info' },
      component: (): Component => import('@/views/manage/project/info.vue')
    },
    {
      path: 'index',
      name: 'manage-project-index',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Case.Manage', title: '项目管理', fullPath: '/manage/project/index' },
      component: (): Component => import('@/views/manage/project/index.vue')
    },
    {
      path: 'update/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-project-update',
      meta: { icon: 'oa_update', role: 'Admin.Case.Manage.Update', hideInMenu: true, title: '项目编辑', fullPath: '/manage/project/update' },
      component: (): Component => import('@/views/manage/project/update.vue')
    },
    {
      path: 'delete/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-project-delete',
      meta: { icon: 'oa_delete', role: 'Admin.Case.Manage.Delete', hideInMenu: true, title: '项目删除', fullPath: '/manage/project/delete' },
      component: (): Component => import('@/views/manage/project/delete.vue')
    },
    {
      path: 'status/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-project-status',
      meta: { icon: 'oa_reload', role: 'Admin.Case.Manage.Update', hideInMenu: true, title: '项目状态修改', fullPath: '/manage/project/status' },
      component: (): Component => import('@/views/manage/project/status.vue')
    },
    {
      path: 'complaint/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-project-complaint',
      meta: { icon: 'oa_tousu', role: 'Admin.Complaint.Business', hideInMenu: true, title: '项目投诉登记', fullPath: '/manage/project/complaint' },
      component: (): Component => import('@/views/manage/complaint/business/createOrUpdate.vue')
    },
    {
      path: 'passlist',
      name: 'manage-project-pass-list',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Case.Pass', title: '风控检查', fullPath: '/manage/project/passlist' },
      component: (): Component => import('@/views/manage/project/pass/index.vue')
    },
    {
      path: 'pass/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-project-pass',
      meta: { icon: 'oa_pass', role: 'Admin.Case.Pass', hideInMenu: true, title: '风控检查', fullPath: '/manage/project/pass' },
      component: (): Component => import('@/views/manage/project/pass/pass.vue')
    },
    {
      path: 'signlist',
      name: 'manage-project-sign-list',
      meta: { icon: 'oa_sign', role: 'Admin.Case.Sign', title: '合同盖章', fullPath: '/manage/project/signlist' },
      component: (): Component => import('@/views/manage/project/sign/index.vue')
    },
    {
      path: 'sign/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-project-sign-info',
      meta: { icon: 'oa_sign', role: 'Admin.Case.Sign', hideInMenu: true, title: '合同盖章', fullPath: '/manage/project/sign' },
      component: (): Component => import('@/views/manage/project/sign/sign.vue')
    },
    {
      path: 'contractinlist',
      name: 'manage-project-contractin-list',
      meta: { icon: 'oa_guidang', role: 'Admin.Case.Sign', title: '合同归档管理', fullPath: '/manage/project/contractinlist' },
      component: (): Component => import('@/views/manage/project/contractIn/index.vue')
    },
    {
      path: 'contractin/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-project-contractin',
      meta: { icon: 'oa_guidang', role: 'Admin.Case.Sign', hideInMenu: true, title: '合同归档', fullPath: '/manage/project/contractin' },
      component: (): Component => import('@/views/manage/project/contractIn/contractIn.vue')
    },
    {
      path: 'supplementlist',
      name: 'manage-project-supplement-list',
      meta: { icon: 'oa_file', role: 'Admin.Case.Files', title: '补充材料管理', fullPath: '/manage/project/supplementlist' },
      component: (): Component => import('@/views/manage/project/supplement/index.vue')
    },
    {
      path: 'supplement/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-project-supplement',
      meta: { icon: 'oa_file', role: 'Admin.Case.Files', hideInMenu: true, title: '补充材料', fullPath: '/manage/project/supplement' },
      component: (): Component => import('@/views/manage/project/supplement/supplement.vue')
    },
    {
      path: 'endlist',
      name: 'manage-project-end-list',
      meta: { icon: 'oa_end', role: 'Admin.Case.EndCase', title: '结案管理', fullPath: '/manage/project/endlist' },
      component: (): Component => import('@/views/manage/project/end/index.vue')
    },
    {
      path: 'end/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-project-end-info',
      meta: { icon: 'oa_end', role: 'Admin.Case.EndCase', hideInMenu: true, title: '项目结案', fullPath: '/manage/project/end' },
      component: (): Component => import('@/views/manage/project/end/end.vue')
    },
    {
      path: 'archivedlist',
      name: 'manage-project-archived-list',
      meta: { icon: 'oa_guidang', role: 'Admin.Case.Archive', title: '归档管理', fullPath: '/manage/project/archivedlist' },
      component: (): Component => import('@/views/manage/project/archived/index.vue')
    },
    {
      path: 'archived/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-project-archived-info',
      meta: { icon: 'oa_guidang', role: 'Admin.Case.Archive', hideInMenu: true, title: '项目归档', fullPath: '/manage/project/archived' },
      component: (): Component => import('@/views/manage/project/archived/archived.vue')
    },
    {
      path: 'leavelist',
      name: 'manage-project-leave-list',
      meta: { icon: 'oa_export', role: 'Admin.Case.Leave', title: '转出管理', fullPath: '/manage/project/leavelist' },
      component: (): Component => import('@/views/manage/project/leave/index.vue')
    },
    {
      path: 'leave/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-project-leave',
      meta: { icon: 'oa_export', role: 'Admin.Case.Leave', hideInMenu: true, title: '四方协议转出', fullPath: '/manage/project/leave' },
      component: (): Component => import('@/views/manage/project/leave/leave.vue')
    },
    {
      path: 'rescissionlist',
      name: 'manage-project-rescission-list',
      meta: { icon: 'oa_jiechu', role: 'Admin.Case.Rescission', title: '解除管理', fullPath: '/manage/project/rescissionlist' },
      component: (): Component => import('@/views/manage/project/rescission/index.vue')
    },
    {
      path: 'rescission/:projectId(\\d+)/:projectTypeCode',
      name: 'manage-project-rescission',
      meta: { icon: 'oa_jiechu', role: 'Admin.Case.Rescission', hideInMenu: true, title: '合同解除', fullPath: '/manage/project/rescission' },
      component: (): Component => import('@/views/manage/project/rescission/rescission.vue')
    },
    {
      path: 'transferlist',
      name: 'manage-project-transfer-list',
      meta: { icon: 'oa_tongbu', role: 'Admin.Case.Transfer', title: '转移管理', fullPath: '/manage/project/transferlist' },
      component: (): Component => import('@/views/manage/project/transfer/index.vue')
    },
    {
      path: 'transfer/:projectId(\\d+)+',
      props: true,
      name: 'manage-project-transfer',
      meta: { icon: 'oa_tongbu', role: 'Admin.Case.Transfer', hideInMenu: true, title: '项目转移', fullPath: '/manage/project/transfer' },
      component: (): Component => import('@/views/manage/project/transfer/transfer.vue')
    }
  ]
}
