import { Component } from 'vue'

import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Bid: RouteRecordRaw = {
  path: 'bid',
  name: 'bid',
  meta: { title: '招投标', icon: 'oa_tender', hideInMenu: true, hideChildrenInMenu: true, fullPath: '/bid' },
  component: RouteView,
  redirect: '/bid/index',
  children: [
    {
      path: 'index',
      name: 'bid-index',
      meta: { icon: 'oa_tender', title: '招投标备案管理', fullPath: '/bid/index' },
      component: (): Component => import('@/views/bid/index.vue')
    },
    {
      path: 'info/:bidId(\\d+)',
      name: 'bid-info',
      meta: { icon: 'oa_tender', hideInMenu: true, title: '招投标详情', fullPath: '/bid/info' },
      component: (): Component => import('@/views/bid/info.vue')
    },
    {
      path: 'create',
      name: 'bid-create',
      meta: { icon: 'oa_plus', hideInMenu: true, title: '录入招投标', fullPath: '/bid/create' },
      component: (): Component => import('@/views/bid/form.vue')
    },
    {
      path: 'update/:bidId(\\d+)',
      name: 'bid-update',
      meta: { icon: 'oa_update', hideInMenu: true, title: '招投标编辑', fullPath: '/bid/update' },
      component: (): Component => import('@/views/bid/form.vue')
    }
  ]
}
