import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const User: RouteRecordRaw = {
  path: 'user',
  name: 'user',
  meta: { title: '用户管理', role: 'Admin.User', icon: 'oa_juese', fullPath: '/manage/user' },
  component: RouteView,
  redirect: '/manage/user/list',
  children: [
    {
      path: 'list',
      name: 'user-list',
      meta: { icon: 'oa_users', role: 'Admin.User.Manage', title: '用户管理', fullPath: '/manage/user/list' },
      component: (): Component => import('@/views/manage/user/list.vue')
    },
    {
      path: 'info/:userId(\\d+)',
      name: 'user-info-id',
      meta: { icon: 'HistoryOutlined', role: 'Admin.User.Manage.Show', hideInMenu: true, title: '用户详情', fullPath: '/manage/user/info/id' },
      component: (): Component => import('@/views/manage/user/userInfo.vue')
    },

    {
      path: 'changorglist',
      name: 'user-changorglist',
      meta: { icon: 'oa_chart_leave', role: 'Admin.User.ChangeOrg', title: '岗位调动管理', fullPath: '/manage/user/changorglist' },
      component: (): Component => import('@/views/manage/user/changeorgList.vue')
    },
    {
      path: 'team',
      name: 'user-team',
      meta: { icon: 'oa_team', role: 'Admin.User.Team', title: '团队管理', fullPath: '/manage/user/team' },
      component: (): Component => import('@/views/manage/team/index.vue')
    },
    {
      path: 'businessDepartment',
      name: 'manage-user-businessDepartment',
      meta: { icon: 'oa_yewubumen', role: '	Admin.User.BusinessDepartment', title: '业务部门管理', fullPath: '/manage/user/businessDepartment' },
      component: (): Component => import('@/views/manage/user/businessDepartment/index.vue')
    },
    // {
    //   path: 'complaint/:userId(\\d+)',
    //   name: 'user-complaint',
    //   meta: { icon: 'oa_tousu', role: 'Admin.Complaint.User', title: '投诉登记', hideInMenu: true, fullPath: '/manage/user/complaint' },
    //   component: (): Component => import('@/views/manage/user/complaint/complaint.vue')
    // },
    {
      path: 'leaveGuide/:userId(\\d+)',
      name: 'user-leaveGuide-id',
      meta: { icon: 'oa_chart_leave', role: 'Admin.User.Manage.Leave', hideInMenu: true, title: '离职', fullPath: '/manage/user/leaveGuide/id' },
      component: (): Component => import('@/views/manage/user/leaveGuide.vue')
    },
    {
      path: 'contractlist',
      name: 'user-contractlist',
      meta: { icon: 'oa_chart_leave', role: 'Admin.User.Contract', title: '合同管理', fullPath: '/manage/user/contractlist' },
      component: (): Component => import('@/views/manage/user/contractList.vue')
    },
    {
      path: 'leaveList',
      name: 'user-leaveList',
      meta: { icon: 'oa_chart_leave', role: 'Admin.User.Recover', title: '已离职用户管理', fullPath: '/manage/user/leaveList' },
      component: (): Component => import('@/views/manage/user/leaveList.vue')
    }
  ]
}
