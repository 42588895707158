import { defineStore } from 'pinia'

import { getPaymentMethodAllTree } from '@/api/system/paymentMethod'

import type { PaymentMethodItem } from '@/types/system/paymentMethod'

export const SystemPaymentMethod = defineStore('SystemPaymentMethod', {
  state: () => {
    return {
      tree: [] as Array<PaymentMethodItem>
    }
  },
  getters: {
    paymentMethodTree: state => state.tree as Array<PaymentMethodItem>,
    paymentMethodChild: state => {
      return (type?: number) => {
        const child: PaymentMethodItem = state.tree.filter(item => item.id === type)[0]
        return child ? (child.children.length ? child.children : [child]) : []
      }
    }
  },
  actions: {
    async getPaymentMethodTree(idList: Array<number>) {
      try {
        const res = await getPaymentMethodAllTree(idList)
        const { data, succeeded } = res
        succeeded ? (this.tree = data) : ''
        return res
      } catch (error) {}
    }
  }
})
