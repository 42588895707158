import request from '@/utils/request'
import type { RequestResult } from '../typing'
import type { CheckExist } from '@/types/typing'
import type { RegionCreateOrUpdate, RegionItem } from '@/types/system/region'

/**
 * 获取地区Tree
 * @param {Array<number>} idList
 * @returns {RequestResult<Array<RegionItem>>}
 */
export async function getRegionAllTree(idList: Array<number>) {
  return request.get<Array<number>, RequestResult<Array<RegionItem>>>(`/api/Region/GetTreeList`, { params: idList })
}

/**
 * 获取 指定节点 tree
 * @param {number} id
 * @returns {RequestResult<RegionItem>}
 */
export async function getRegionNodeTree(id: number) {
  return request.get<number, RequestResult<RegionItem>>(`/api/Region/GetTree/${id}`)
}

/**
 * 获取节点ID下子节点
 * @param {number} id
 * @returns {RequestResult<Array<RegionItem>>}
 */
export async function getRegionChildTree(id: number) {
  return request.get<number, RequestResult<Array<RegionItem>>>(`/api/Region/GetChildList/${id}`)
}

/**
 * 根据Id 获取地区详情
 * @param {number} id
 * @returns {RequestResult<RegionItem>}
 */
export async function getRegionInfo(id: number) {
  return request.get<number, RequestResult<RegionItem>>(`/api/Region/GetById/${id}`)
}

/**
 * 检查是否重复
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<boolean>}
 */
export async function checkRegionName(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/Region/CheckExist`, CheckExist)
}

/**
 * 添加地区
 * @param {RegionCreateOrUpdate} region
 * @returns {RequestResult<null>}
 */
export async function createRegion(region: RegionCreateOrUpdate) {
  return request.post<RegionCreateOrUpdate, RequestResult<null>>('/api/Region/Create', region)
}

/**
 * 编辑地区
 * @param {RegionCreateOrUpdate} region
 * @returns {RequestResult<null>}
 */
export async function updateRegion(region: RegionCreateOrUpdate) {
  return request.put<RegionCreateOrUpdate, RequestResult<null>>('/api/Region/Update', region)
}

/**
 * 删除地区
 * @param {number} id
 * @returns {RequestResult<null>}
 */
export async function deleteRegion(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/Region/Delete/${id}`)
}

/**
 * 软删除地区
 * @param {number} id
 * @returns {RequestResult<null>}
 */
export async function deleteRegionSoft(id: number) {
  return request.post<number, RequestResult<null>>(`/api/Region/SoftDelete/${id}`)
}
