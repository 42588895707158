import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'
import AccountLayout from '@/layouts/account.vue'

export const Account: RouteRecordRaw = {
  name: 'Account',
  path: '/account',
  meta: { title: '账号', fullPath: '/account' },
  component: AccountLayout,
  children: [
    {
      path: '/account/login',
      name: 'login',
      meta: { icon: 'AppstoreAddOutlined', title: '登录', fullPath: '/account/login' },
      component: () => import(/* webpackChunkName: "user" */ '@/views/account/login.vue')
    },
    {
      path: '/account/findpwd',
      name: 'findpwd',
      meta: { icon: 'AppstoreAddOutlined', title: '找回密码', fullPath: '/account/findpwd' },
      component: () => import(/* webpackChunkName: "user" */ '@/views/account/findpwd.vue')
    },
    {
      path: '/account/changepwd',
      name: 'changepwd',
      meta: { icon: 'AppstoreAddOutlined', title: '修改密码', fullPath: '/account/changepwd' },
      component: () => import(/* webpackChunkName: "user" */ '@/views/account/changepwd.vue')
    }
  ]
}

export const MyAccount: RouteRecordRaw = {
  name: 'MyAccount',
  path: 'myaccount',
  meta: { title: '个人中心', hideInMenu: true, hideChildrenInMenu: true, icon: 'oa_user', fullPath: '/myaccount' },
  component: RouteView,
  redirect: '/myaccount/index',
  children: [
    {
      name: 'MyAccountIndex',
      path: 'index',
      meta: { icon: 'oa_user', title: '个人中心', fullPath: '/myaccount/index' },
      component: () => import(/* webpackChunkName: "MyAccount" */ '@/views/account/center/index.vue')
    },
    {
      name: 'MyAccountSettings',
      path: 'settings',
      meta: {
        title: '个人设置',
        hideChildrenInMenu: true,
        mergeTab: true,
        icon: 'oa_user_settings',
        fullPath: '/myaccount/settings'
      },
      redirect: '/myaccount/settings/base',
      component: () => import(/* webpackChunkName: "MyAccount" */ '@/views/account/settings/index.vue'),
      children: [
        {
          name: 'BaseSettings',
          path: 'base',
          meta: { icon: 'oa_user_settings', title: '基础设置', fullPath: '/myaccount/base' },
          component: () => import(/* webpackChunkName: "MyAccount" */ '@/views/account/settings/pages/base.vue')
        },
        {
          name: 'SecuritySettings',
          path: 'security',
          meta: { icon: 'HistoryOutlined', title: '安全设置', fullPath: '/myaccount/security' },
          component: () => import(/* webpackChunkName: "MyAccount" */ '@/views/account/settings/pages/security.vue')
        },
        {
          name: 'BindingSettings',
          path: 'binding',
          meta: { icon: 'HistoryOutlined', title: '账户绑定', fullPath: '/myaccount/binding' },
          component: () => import(/* webpackChunkName: "MyAccount" */ '@/views/account/settings/pages/binding.vue')
        },
        {
          name: 'NotificationSettings',
          path: 'notification',
          meta: { icon: 'HistoryOutlined', title: '消息通知', fullPath: '/myaccount/notification' },
          component: () => import(/* webpackChunkName: "MyAccount" */ '@/views/account/settings/pages/notification.vue')
        }
      ]
    }
  ]
}
