<template>
  <div class="breadcrumb">
    <a-page-header :breadcrumb="props.showBreadcrumb ? { routes: breadcrumb, itemRender: customBreadcrumbRender } : null" :title="props.title" :sub-title="props.subTitle">
      <template v-if="hasTags" #tags>
        <slot name="tags" />
      </template>
      <template v-if="hasExtra" #extra>
        <slot name="extra" />
      </template>
      <template v-if="hasFooter" #footer>
        <slot name="footer" />
      </template>
      <page-header-content v-if="hasContent">
        <template v-if="$slots.content" #content>
          <slot name="content" />
        </template>
      </page-header-content>
    </a-page-header>
  </div>
</template>

<script setup lang="ts">
  import { useSlots, h, VNodeChild, withCtx, createTextVNode, watch, ref } from 'vue'
  import PageHeaderContent from './page-header-content.vue'
  import { useRoute, RouterLink } from 'vue-router'
  defineOptions({ name: 'PageContainer' })
  export interface Tab {
    key: string
    tab: string | VNodeChild
  }

  export interface Route {
    path: string
    breadcrumbName: string
    children: Array<{
      path: string
      breadcrumbName: string
    }>
  }

  export interface BreadcrumbItemRender {
    route: Route
    params: Record<any, any>
    routes: Route[]
    paths: string[]
  }

  const slots = useSlots()
  const { extra: hasExtra, footer: hasFooter, content: hasContent, tags: hasTags } = slots

  const props = defineProps({
    title: {
      type: [String, Boolean],
      default: false
    },
    subTitle: {
      type: [String, Boolean],
      default: false
    },
    showBreadcrumb: {
      type: Boolean,
      default: true
    }
  })

  const route = useRoute()

  interface BreadcrumbItem {
    path: string
    breadcrumbName: string
  }

  const breadcrumb = ref<BreadcrumbItem[]>([])

  const updateBreadcrumb = () => {
    breadcrumb.value = route.matched.concat().map(r => {
      return {
        path: r.path,
        breadcrumbName: r.path === '/' ? '工作台' : `${r.meta.title}`
      }
    })
  }

  watch(
    () => route.path,
    () => {
      updateBreadcrumb()
    },
    {
      immediate: true
    }
  )

  // 自定义面包屑渲染逻辑
  const customBreadcrumbRender = ({ route, params, routes }: BreadcrumbItemRender) => {
    const breadcrumbName = route.breadcrumbName
    return (
      (routes.indexOf(route) === routes.length - 1 && h('span', null, { default: withCtx(() => [createTextVNode(breadcrumbName)]) })) ||
      h(RouterLink, { to: { path: route.path || '/', params } }, { default: withCtx(() => [createTextVNode(breadcrumbName)]) })
    )
  }
</script>

<style lang="less" scoped>
  .use_layout {
    .breadcrumb {
      margin: 0 -16px 20px;
    }
  }

  .breadcrumb {
    margin-bottom: 20px;
    background-color: #fff;

    .ant-page-header {
      padding: 8px 14px;
    }
  }
</style>
