import request from '@/utils/request'

import type { PageResultData, RequestResult } from '../typing'
import type {
  ProjectParams,
  ProjectItemForMy,
  ProjectItemForTeam,
  ProjectItemForAdmin,
  ProjectInfo,
  ProjectCheckParams,
  ProjectCheckDataItem,
  ProjectInfoTeam,
  ProjectInfoLetter,
  ProjectInfoFiles,
  ProjectInfoInvoice,
  ProjectInfoPayment,
  ProjectInfoRefund,
  ProjectInfoComplaint,
  ProjectInfoStatus,
  ProjectInfoConflict,
  ProjectInfoProgress,
  ProjectSign,
  ASignProject,
  projectArchived,
  ProjectContractIn,
  ProjectEnd,
  ProjectLeaveCase,
  ProjectPass,
  ProjectRelieve,
  ProjectStatusChange,
  ProjectSupplement,
  ProjectTransfer,
  ProjectTransferItem,
  ProjectUserPass,
  ProjectUpdateRelation,
  ProjectUpdateTeam,
  ProjectUpdateFinance,
  ProjectApplayEndBase,
  ProjectUpdateProject,
  ProjectInfoApplayEnd
} from '@/types/project'

/**
 * 项目冲突检索
 * @param checkForm
 * @returns {RequestResult<PageResultData<ProjectCheckDataItem>>}
 */
export async function checkProject(checkForm: ProjectCheckParams) {
  return request.post<ProjectCheckParams, RequestResult<PageResultData<ProjectCheckDataItem>>>(`/api/Elastic/GetPagedListForProjectConflict`, checkForm)
}

//#region 项目详情

/**
 * 获取项目详情
 * @param { number } projectId
 * @returns { RequestResult<ProjectInfo> }
 */
export async function getProjectInfo(projectId: number) {
  return request.get<number, RequestResult<ProjectInfo>>(`/api/Project/getById/${projectId}`)
}

/**
 * 获取项目详情(办案团队)
 * @param { number } projectId
 * @returns { RequestResult<Array<ProjectInfoTeam>>}
 */
export async function getProjectInfoForTeam(projectId: number) {
  return request.get<number, RequestResult<Array<ProjectInfoTeam>>>(`/api/Project/GetUserList/${projectId}`)
}

/**
 * 获取项目详情(函书申请记录)
 * @param { number } projectId
 * @returns { RequestResult<Array<ProjectInfoLetter>> }
 */
export async function getProjectInfoForLetter(projectId: number) {
  return request.get<number, RequestResult<Array<ProjectInfoLetter>>>(`/api/Project/GetLetterList/${projectId}`)
}

/**
 * 获取项目详情(文件信息)
 * @param { number } projectId
 * @returns { RequestResult<Array<ProjectInfoFiles>> }
 */
export async function getProjectInfoForFiles(projectId: number) {
  return request.get<number, RequestResult<Array<ProjectInfoFiles>>>(`/api/Project/GetFilesList/${projectId}`)
}

/**
 * 获取项目详情(发票信息)
 * @param { number } projectId
 * @returns { RequestResult<Array<ProjectInfoInvoice>> }
 */
export async function getProjectInfoForInvoice(projectId: number) {
  return request.get<number, RequestResult<Array<ProjectInfoInvoice>>>(`/api/Project/GetInvoiceList/${projectId}`)
}

/**
 * 获取项目详情(付款信息)
 * @param { number } projectId
 * @returns { RequestResult<Array<ProjectInfoPayment>> }
 */
export async function getProjectInfoForPayment(projectId: number) {
  return request.get<number, RequestResult<Array<ProjectInfoPayment>>>(`/api/Project/GetPaymentList/${projectId}`)
}

/**
 * 获取项目详情(退费信息)
 * @param { number } projectId
 * @returns { RequestResult<Array<ProjectInfoRefund>>}
 */
export async function getProjectInfoForRefund(projectId: number) {
  return request.get<number, RequestResult<Array<ProjectInfoRefund>>>(`/api/Project/GetRefundList/${projectId}`)
}

/**
 * 获取项目详情(投诉检索记录)
 * @param { number } projectId
 * @returns { RequestResult<Array<ProjectInfoComplaint>> }
 */
export async function getProjectInfoForComplaint(projectId: number) {
  return request.get<number, RequestResult<Array<ProjectInfoComplaint>>>(`/api/Project/GetComplaintList/${projectId}`)
}

/**
 * 获取项目详情(审批记录)
 * @param { number } projectId
 * @returns { RequestResult<Array<ProjectInfoStatus>>}
 */
export async function getProjectInfoForStatus(projectId: number) {
  return request.get<number, RequestResult<Array<ProjectInfoStatus>>>(`/api/Project/GetStatusList/${projectId}`)
}

/**
 * 获取项目详情(冲突检索记录)
 * @param { number } projectId
 * @returns { RequestResult<Array<ProjectInfoConflict>>}
 */
export async function getProjectInfoForConflict(projectId: number) {
  return request.get<number, RequestResult<Array<ProjectInfoConflict>>>(`/api/Project/GetConflictLIst/${projectId}`)
}

/**
 * 获取项目详情(进度信息)
 * @param { number } projectId
 * @returns { RequestResult<Array<ProjectInfoProgress>> }
 */
export async function getProjectInfoForProgress(projectId: number) {
  return request.get<number, RequestResult<Array<ProjectInfoProgress>>>(`/api/Project/GetProgress/${projectId}`)
}

/**
 * 获取项目申请结案信息
 * @param { number } projectId
 * @returns { RequestResult<ProjectInfoApplayEnd> }
 */
export async function getProjectInfoForApplayEnd(projectId: number) {
  return request.get<number, RequestResult<ProjectInfoApplayEnd>>(`/api/Project/GetApplayEnd/${projectId}`)
}

//#endregion

//#region 项目列表

/**
 * 获取项目列表
 * @param { ProjectParams } project
 * @returns { RequestResult<PageResultData<ProjectItemForMy>> }
 */
export async function getProjectPageList(project: ProjectParams) {
  return request.get<ProjectParams, RequestResult<PageResultData<ProjectItemForMy>>>('/api/Project/GetPagedList', { params: project })
}

/**
 * 获取团队项目列表(需要我审批的)
 * @param project
 * @returns { RequestResult<PageResultData<ProjectItemForTeam>> }
 */
export async function getProjectPageListForTeam(project: ProjectParams) {
  return request.get<ProjectParams, RequestResult<PageResultData<ProjectItemForTeam>>>('/api/Project/GetPagedListForTeam', { params: project })
}

/**
 * 获取项目列表
 * @param project
 * @returns { RequestResult<PageResultData<ProjectItemForAdmin>> }
 */
export async function getProjectPageListForAdmin(project: ProjectParams) {
  return request.get<ProjectParams, RequestResult<PageResultData<ProjectItemForAdmin>>>('/api/Project/GetPagedListForAdmin', { params: project })
}

//#endregion

//#region 项目导出

/**
 * 导出项目列表
 * @param {ProjectParams} data
 * @returns
 */
export async function ExportExcelProjectForAdmin(data: ProjectParams) {
  return request.post<ProjectParams, RequestResult<null>>('/api/Project/ExportToExcel', data, { responseType: 'blob' })
}

/**
 * 导出项目信息
 * @param { number } projectId
 * @returns
 */
export async function exportProject(projectId: number) {
  return request.post<number, RequestResult<null>>(`/api/Project/Export/${projectId}`, undefined, { responseType: 'blob' })
}

//#endregion

export async function deleteProject(projectId: number) {
  return request.delete<number, RequestResult<null>>(`/api/Project/Delete/${projectId}`)
}

//#region 项目流程操作

/**
 * 审批自己\团队项目
 * @param data
 * @returns
 */
export async function userPassProject(data: ProjectUserPass) {
  return request.post<ProjectUserPass, RequestResult<boolean>>('/api/Project/PassTeamProject', data)
}

/**
 * 项目审批
 * @param {ProjectPass} data
 * @returns {RequestResult<boolean>}
 */
export async function passProject(data: ProjectPass) {
  return request.post<ProjectPass, RequestResult<boolean>>('/api/Project/PassCase', data)
}

/**
 * 项目盖章
 * @param {ProjectSign} data
 * @returns { RequestResult<boolean> }
 */
export async function signProject(data: ProjectSign) {
  return request.post<ProjectPass, RequestResult<boolean>>('/api/Project/SignCase', data)
}

export async function aSignProject(data: ASignProject) {
  return request.post<ASignProject, RequestResult<boolean>>(`/api/Asign/ContractSign`, data)
}

/**
 * 项目合同归档
 * @param {ProjectContractIn} data
 * @returns { RequestResult<boolean> }
 */
export async function projectContractIn(data: ProjectContractIn) {
  return request.post<ProjectContractIn, RequestResult<boolean>>('/api/Project/SetContractIn', data)
}

/**
 * 项目申请结案
 * @param { ProjectApplayEndBase } data
 * @returns { RequestResult<boolean> }
 */
export async function applyEndProject(data: ProjectApplayEndBase) {
  return request.post<ProjectApplayEndBase, RequestResult<boolean>>('/api/Project/ApplyForEndStatus', data)
}

/**
 * 撤销申请结案
 * @param { number} projectId
 * @returns { RequestResult<boolean>}
 */
export async function revokeEndApply(projectId: number) {
  return request.post<number, RequestResult<boolean>>(`/api/Project/RevokeEndStatus/${projectId}`)
}

/**
 * 项目结案
 * @param {ProjectEnd} data
 * @returns { RequestResult<boolean> }
 */
export async function endProject(data: ProjectEnd) {
  return request.post<ProjectEnd, RequestResult<boolean>>(`/api/Project/EndCase`, data)
}

/**
 * 项目归档
 * @param {projectArchived} data
 * @returns {RequestResult<boolean>}
 */
export async function archivedProject(data: projectArchived) {
  return request.post<projectArchived, RequestResult<boolean>>(`/api/Project/ArchivedCase`, data)
}

/**
 * 项目补充材料
 * @param { ProjectSupplement } data
 * @returns { RequestResult<boolean> }
 */
export async function supplementProject(data: ProjectSupplement) {
  return request.post<ProjectSupplement, RequestResult<boolean>>('/api/Project/SupplementDocument', data)
}

/**
 * 获取项目列表(项目转移)
 * @param {Array<number|string>} params
 * @returns {RequestResult<PageResultData<ProjectTransferItem>>}
 */
export async function getProjectListForTransfer(idList: Array<number | string>) {
  return request.get<Array<number | string>, RequestResult<Array<ProjectTransferItem>>>('/api/Project/GetListForTransfer', { params: { idList } })
}

/**
 * 项目转移
 * @param {ProjectTransfer} data
 * @returns {RequestResult<boolean> }
 */
export async function transferProject(data: ProjectTransfer) {
  return request.post<ProjectTransfer, RequestResult<boolean>>(`/api/Project/TransferProject`, data)
}

/**
 * 项目四方转出
 * @param {ProjectLeaveCase} params
 * @returns {RequestResult<PageResultData<boolean>>}
 */
export async function leaveProject(data: ProjectLeaveCase) {
  return request.post<ProjectLeaveCase, RequestResult<PageResultData<boolean>>>('/api/Project/SetTransferStatus', data)
}

/**
 * 项目合同解除
 * @param {ProjectRelieve} params
 * @returns {RequestResult<PageResultData<boolean>>}
 */
export async function relieveProject(data: ProjectRelieve) {
  return request.post<ProjectRelieve, RequestResult<PageResultData<boolean>>>('/api/Project/SetTerminateStatus', data)
}

/**
 * 项目状态调整
 * @param { ProjectStatusChange } params
 * @returns {RequestResult<PageResultData<boolean>>}
 */
export async function statusChangeProject(data: ProjectStatusChange) {
  return request.post<ProjectStatusChange, RequestResult<PageResultData<boolean>>>('/api/Project/ChangeProjectStatus', data)
}

//#endregion

//#region

/**
 * 更新项目基本信息
 * @param { ProjectUpdateProject } data
 * @returns
 */
export async function updateProjectBase(data: ProjectUpdateProject) {
  return request.put<ProjectUpdateProject, RequestResult<null>>(`/api/Project/UpdateProject`, data)
}

/**
 * 更新项目相关方信息
 * @param { ProjectUpdateRelation } data
 * @returns
 */
export async function udpateProjectRelevant(data: ProjectUpdateRelation) {
  return request.put<ProjectUpdateRelation, RequestResult<null>>(`/api/Project/UpdateRelation`, data)
}

/**
 * 更新项目办案团队,主办律师除外
 * @param data
 * @returns
 */
export async function updateProjectTeam(data: ProjectUpdateTeam) {
  return request.put<ProjectUpdateTeam, RequestResult<null>>(`/api/Project/UpdateLawyer`, data)
}

/**
 * 更新项目财务信息
 * @param data
 * @returns
 */
export async function udpateProjectFinance(data: ProjectUpdateFinance) {
  return request.put<ProjectUpdateFinance, RequestResult<null>>(`/api/Project/UpdatePayment`, data)
}
//#endregion
