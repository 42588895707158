import { defineStore } from 'pinia'

import { getIndustryAllTree } from '@/api/system/industry'

import type { IndustryItem } from '@/types/system/industry'

export const SystemIndustry = defineStore('SystemIndustry', {
  state: () => {
    return {
      tree: [] as Array<IndustryItem>
    }
  },
  getters: {
    industryTree: state => state.tree as Array<IndustryItem>
  },
  actions: {
    async getIndustryTree(idList: Array<number> = []) {
      try {
        const res = await getIndustryAllTree(idList)
        const { data, succeeded } = res
        succeeded ? (this.tree = data) : ''
        return res
      } catch (error) {}
    }
  }
})
