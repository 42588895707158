import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Administration: RouteRecordRaw = {
  path: 'administration',
  name: 'administration',
  meta: { title: '行政支持', icon: 'oa_xingzheng', fullPath: '/administration' },
  component: RouteView,
  redirect: '/administration/addressBook',
  children: [
    {
      path: 'sign',
      name: 'administration-sign',
      meta: { icon: 'oa_sign', title: '用章申请', fullPath: '/administration/sign' },
      component: (): Component => import('@/views/administration/sign/index.vue')
    },
    {
      path: 'sign/info/:id',
      name: 'administration-sign-info',
      meta: { icon: 'oa_sign', hideInMenu: true, title: '用章申请详情', fullPath: '/administration/info' },
      component: (): Component => import('@/views/administration/sign/info.vue')
    },
    {
      path: 'addressBook',
      name: 'administration-addressBook',
      meta: { icon: 'oa_addressbook', title: '通讯录', fullPath: '/administration/addressBook' },
      component: (): Component => import('@/views/administration/addressBook.vue')
    },

    {
      path: 'notice',
      name: 'administration-notice',
      meta: { icon: 'oa_notice', title: '公告', fullPath: '/administration/notice' },
      component: (): Component => import('@/views/administration/notice.vue')
    }
  ]
}
