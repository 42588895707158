<template>
  <div style="border: 1px solid #ccc">
    <template v-if="props.hasToolbar">
      <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
    </template>
    <Editor :style="{ height: `${props.height}px`, 'overflow-y': 'hidden' }" v-bind="$attrs" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
  </div>
</template>
<script setup lang="ts">
  import { onBeforeUnmount, ref, shallowRef } from 'vue'
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  import request from '@/utils/request'

  import { GetOssSignature } from '@/api/system/oss'

  import type { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'

  defineOptions({ name: 'EditIndex' })

  const props = defineProps({
    disabled: {
      // 禁用\启用编辑器
      type: Boolean,
      default: false
    },
    height: {
      // 编辑器高度
      type: Number,
      default: 500
    },
    placeholder: {
      // 编辑器占位符
      type: String,
      default: '请输入内容...'
    },
    hasToolbar: {
      // 是否显示工具栏
      type: Boolean,
      default: true
    }
  })

  // 编辑器模式
  const mode = ref<'default' | 'simple'>('default')
  // 编辑器实例，必须用 shallowRef
  const editorRef = shallowRef<IDomEditor>()

  // 记录 editor 实例，重要！
  const handleCreated = (editor: IDomEditor) => {
    editorRef.value = editor
    if (props.disabled) {
      editorRef.value.disable()
    }
  }

  // 工具栏配置
  const toolbarConfig: Partial<IToolbarConfig> = {
    excludeKeys: ['group-video', 'insertImage']
  }

  type InsertFnType = (url: string, alt: string, href: string) => void

  // 编辑器配置
  const editorConfig: Partial<IEditorConfig> = {
    placeholder: props.placeholder,
    MENU_CONF: {
      uploadImage: {
        // 自定义上传
        async customUpload(file: File, insertFn: InsertFnType) {
          const fileData = { fileName: file.name, fileType: 'customer' }
          // 签名
          GetOssSignature(fileData).then(respose => {
            const { data: res, succeeded } = respose
            if (succeeded) {
              const data = new FormData()
              for (const item in res) {
                // @ts-expect-error 动态属性获取
                data.append(item, res[item])
              }
              data.append('File', file)

              const config = {
                headers: {
                  contentType: 'multipart/form-data' // 发送请求内容数据类型
                }
              }
              // 上传
              request.post(res.host, data, config).then(response => {
                // 最后插入图片
                insertFn(response.data.fileUrl, file.name, response.data.fileUrl)
              })
            }
          })
        }
      }
    }
  }

  // 组件销毁时，也及时销毁编辑器
  onBeforeUnmount(() => {
    const editor = editorRef.value
    if (editor == null) return
    editor.destroy()
  })
</script>
<style lang="less">
  @import url('@wangeditor/editor/dist/css/style.css');
</style>
