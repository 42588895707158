import request from '@/utils/request'
import type { RequestResult } from '../typing'
import type { CheckExist } from '@/types/typing'
import type { OrganizationCreateOrUpdate, OrganizationInfo, OrganizationItem, OrganizationType } from '@/types/system/organization'

/**
 * 获取受理机构Tree
 * @returns {RequestResult<any>}
 */
export async function getOrganizationAllTreeList() {
  return request.get<number, RequestResult<Array<OrganizationItem>>>(`/api/Organization/GetTreeList`)
}
/**
 * 根据organizationType 获取指定受理机构Tree
 * @returns {RequestResult<any>}
 */
export async function getOrganizationTreeList(organizationType: OrganizationType) {
  return request.get<number, RequestResult<Array<OrganizationItem>>>(`/api/Organization/GetTreeList/${organizationType}`)
}

export async function getOrganizationInfo(id: number) {
  return request.get<number, RequestResult<OrganizationInfo>>(`/api/Organization/GetById/${id}`)
}

/**
 * 检查是否重复
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<boolean>}
 */
export async function checkOrganizationName(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/Organization/CheckExist`, CheckExist)
}

/**
 * 添加受理机构
 * @param { OrganizationCreateOrUpdate } data
 * @returns { RequestResult<null> }
 */
export async function createOrganization(data: OrganizationCreateOrUpdate) {
  return request.post<OrganizationCreateOrUpdate, RequestResult<null>>(`/api/Organization/Create`, data)
}

/**
 * 编辑受理机构
 * @param { OrganizationCreateOrUpdate }  data
 * @returns { RequestResult<null>}
 */
export async function updataOrgainzation(data: OrganizationCreateOrUpdate) {
  return request.put<OrganizationCreateOrUpdate, RequestResult<null>>(`/api/Organization/Update`, data)
}

/**
 * 删除受理机构
 * @param { number} id
 * @returns { RequestResult<null> }
 */
export async function deleteOrganization(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/Organization/Delete/${id}`)
}
