import { Component } from 'vue'

import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Case: RouteRecordRaw = {
  path: 'case',
  name: 'case',
  meta: { title: '业务办理', icon: 'oa_yewuguanli', fullPath: '/case' },
  component: RouteView,
  redirect: '/case/index',
  children: [
    {
      path: 'check',
      name: 'case-check',
      meta: { icon: 'oa_confirm', title: '冲突预检', fullPath: '/case/check' },
      component: (): Component => import('@/views/case/check/index.vue')
    },
    {
      path: 'info/:contractId(\\d+)',
      name: 'case-info',
      meta: { icon: 'oa_show', hideInMenu: true, title: '案件详情', fullPath: '/case/info' },
      component: (): Component => import('@/views/case/info.vue')
    },
    {
      path: 'update/:contractId(\\d+)',
      name: 'case-update',
      meta: { icon: 'oa_update', hideInMenu: true, title: '案件编辑', fullPath: '/case/update' },
      component: (): Component => import('@/views/case/update.vue')
    },
    {
      path: 'guide',
      name: 'case-guide',
      meta: { icon: 'oa_plus', title: '添加案件', fullPath: '/case/guide' },
      component: (): Component => import('@/views/case/create/guide.vue')
    },
    {
      path: 'create',
      name: 'case-create',
      meta: { icon: 'oa_plus', hideInMenu: true, title: '添加案件', fullPath: '/case/create' },
      component: (): Component => import('@/views/case/create/create.vue')
    },
    {
      path: 'bid',
      name: 'case-bid',
      meta: { icon: 'oa_tender', title: '招投标备案', fullPath: '/case/bid' },
      redirect: '/bid/index'
    },
    // 审批我的案件
    {
      path: 'checkGuide/:contractId(\\d+)',
      name: 'case-check-guide',
      meta: { icon: 'oa_pass', hideInMenu: true, title: '律师冲突自检-引导页', fullPath: '/case/checkguide' },
      component: (): Component => import('@/views/case/check/checkGuide.vue')
    },
    {
      path: 'checkMy/:contractId(\\d+)',
      name: 'case-check-my',
      meta: { icon: 'oa_pass', hideInMenu: true, title: '提交风控审批', fullPath: '/case/checkmy' },
      component: (): Component => import('@/views/case/check/checkMy.vue')
    },
    // 审批团队成员案件
    {
      path: 'checkPartnerList',
      name: 'case-check-partner-list',
      meta: { icon: 'oa_pass', title: '审核团队成员案件列表', fullPath: '/case/checkPartnerList' },
      component: (): Component => import('@/views/case/check/checkPartnerList.vue')
    },
    {
      path: 'checkPartner/:contractId(\\d+)',
      name: 'case-check-partner',
      meta: { icon: 'oa_pass', hideInMenu: true, title: '审核团队成员案件', fullPath: '/case/checkPartner' },
      component: (): Component => import('@/views/case/check/checkPartner.vue')
    },

    {
      path: 'index',
      name: 'case-index',
      meta: { icon: 'oa_yewuguanli', title: '案件管理', fullPath: '/case/index' },
      component: (): Component => import('@/views/case/index.vue')
    },
    {
      path: 'letter',
      name: 'case-letter',
      meta: { icon: 'oa_letter', title: '函书申领', fullPath: '/case/letter' },
      component: (): Component => import('@/views/letter/index.vue')
    },

    {
      path: 'applyendList',
      name: 'case-applyend-list',
      meta: { icon: 'oa_jlpc', title: '申请结案', fullPath: '/case/applyendList' },
      component: (): Component => import('@/views/case/applyend/index.vue')
    },
    {
      path: 'applyend/:contractId(\\d+)',
      name: 'case-applyend',
      meta: { icon: 'oa_jlpc', hideInMenu: true, title: '申请结案', fullPath: '/case/applyend' },
      component: (): Component => import('@/views/case/applyend/applyend.vue')
    },
    {
      path: 'invoicelist',
      name: 'case-invoicelist',
      meta: { icon: 'oa_invoice', title: '开票登记', fullPath: '/case/invoiceList' },
      component: (): Component => import('@/views/case/invoice/index.vue')
    },
    {
      path: 'invoice/create/:contractId(\\d+)/:code?',
      name: 'case-invoice-create',
      props: true,
      meta: { icon: 'oa_invoice', hideInMenu: true, title: '申请开票', fullPath: '/case/invoice/create' },
      component: (): Component => import('@/views/case/invoice/create.vue')
    },
    {
      path: 'paymentlist',
      name: 'case-paymentlist',
      meta: { icon: 'oa_pay', title: '缴费登记', fullPath: '/case/paymentList' },
      component: (): Component => import('@/views/case/payment/index.vue')
    },
    {
      path: 'payment/create/:contractId(\\d+)/:code?',
      name: 'case-payment-create',
      meta: { icon: 'oa_pay', hideInMenu: true, title: '申请缴费', fullPath: '/case/payment/create' },
      component: (): Component => import('@/views/case/payment/create.vue')
    },
    {
      path: 'refundlist',
      name: 'case-refund-list',
      meta: { icon: 'oa_refund', title: '退款登记', fullPath: '/case/refundlist' },
      component: (): Component => import('@/views/case/refund/index.vue')
    },
    {
      path: 'refund/:contractId(\\d+)',
      name: 'case-refund-create',
      meta: { icon: 'oa_refund', hideInMenu: true, title: '申请退款', fullPath: '/case/refund/create' },
      component: (): Component => import('@/views/case/refund/create.vue')
    },
    {
      path: 'changeAccountlist',
      name: 'case-changeAccountlist',
      meta: { icon: 'oa_invoice', title: '转账登记', fullPath: '/case/changeAccountList' },
      component: (): Component => import('@/views/case/changeAccount/index.vue')
    }
  ]
}
