import { Component } from 'vue'

import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Client: RouteRecordRaw = {
  path: 'client',
  name: 'client',
  meta: { title: '客户管理', icon: 'oa_client', fullPath: '/client' },
  component: RouteView,
  redirect: '/client/index',
  children: [
    {
      path: 'index',
      name: 'client-index',
      meta: { icon: 'oa_users', title: '委托人管理', fullPath: '/client/index' },
      component: (): Component => import('@/views/client/index.vue')
    },
    {
      path: 'create/:contractId(\\d+)',
      name: 'client-create',
      meta: { icon: 'oa_users', hideInMenu: true, title: '创建委托人', fullPath: '/client/create/id' },
      component: (): Component => import('@/views/client/create.vue')
    },
    {
      path: 'update/:id(\\d+)',
      name: 'client-update-id',
      meta: { icon: 'oa_users', hideInMenu: true, title: '编辑委托人', fullPath: '/client/update/id' },
      component: (): Component => import('@/views/client/update.vue')
    },
    {
      path: 'info/:id(\\d+)',
      name: 'client-info-id',
      meta: { icon: 'oa_users', hideInMenu: true, title: '委托人详情', fullPath: '/client/info/id' },
      component: (): Component => import('@/views/client/info.vue')
    },
    {
      path: 'import',
      name: 'client-import',
      meta: { icon: 'oa_import', hideInMenu: true, title: '委托人批量导入', fullPath: '/client/import' },
      component: (): Component => import('@/views/client/import.vue')
    }
  ]
}
