import { idCardNoReg, passWordReg, passWordReg1, passWordReg2, phoneNumberReg } from '@/config'
import { ProjectCliectIdCardTypeCode } from '@/stores/modules/projectClient'

/**
 * 根据当前时间返回问候语
 * @returns {string}
 */
export const greetings = (): string => {
  const now = new Date(),
    hour = now.getHours()
  if (hour < 6) {
    return '凌晨好!'
  } else if (hour < 9) {
    return '早上好!'
  } else if (hour < 12) {
    return '上午好!'
  } else if (hour < 14) {
    return '中午好!'
  } else if (hour < 17) {
    return '下午好!'
  } else if (hour < 19) {
    return '傍晚好!'
  } else if (hour < 22) {
    return '晚上好!'
  } else {
    return '夜里好!'
  }
}

/**
 * 生成随机uuid
 * @returns {string} uuid
 */
export const generateUUID = () => {
  let d = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(32)
  })
  return uuid
}

/**
 * 生成指定范围随机数
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const randomNum = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min

/**
 * 数字千分位分割
 * @param {number} n 数值
 * @param {string} prefix 前缀
 * @param {string} temp 分隔符
 * @returns { string }
 */
export const formatNumber = (n: number | string, prefix = '', temp = ','): string => {
  return prefix + n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, temp)
}

/**
 * 数组乱序
 * @param {Array<any>} arr
 * @returns {Array<any>}
 */
export const arrScrambling = (arr: Array<any>): Array<any> => {
  for (let i = 0; i < arr.length; i++) {
    const randomIndex = Math.round(Math.random() * (arr.length - 1 - i)) + i
    ;[arr[i], arr[randomIndex]] = [arr[randomIndex], arr[i]]
  }
  return arr
}

/**
 * 数组扁平化
 * @param {Array<any>} arr
 * @returns
 */
export const flatten = (arr: Array<any>): Array<any> => {
  let result: Array<any> = []

  for (let i = 0; i < arr.length; i++) {
    if (Array.isArray(arr[i])) {
      result = result.concat(flatten(arr[i]))
    } else {
      result.push(arr[i])
    }
  }
  return result
}

// Array<Object> 根据child 展开
export const flattenObject = (arr: Array<any>): Array<any> => {
  return arr.reduce((result, item) => {
    return result.concat(item, Array.isArray(item.children) ? flattenObject(item.children) : [])
  }, [])
}

/**
 * 数组中随机数
 * @param {Array<any>} arr
 * @returns {any}
 */
export const sample = (arr: Array<any>): any => arr[Math.floor(Math.random() * arr.length)]

/**
 * 生成随机字符串
 * @param {number} len 长度
 * @returns {string}
 */
export const randomString = (len: number): string => {
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz123456789'
  const strLen = chars.length
  let randomStr = ''
  for (let i = 0; i < len; i++) {
    randomStr += chars.charAt(Math.floor(Math.random() * strLen))
  }
  return randomStr
}

/**
 * 字符串首字母大小
 * @param {string} str
 * @returns {string}
 */
export const fistLetterUpper = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * 手机号隐藏中间4位
 * @param {string} tel
 * @returns {string}
 */
export const telFormat = (tel: string): string => {
  tel = String(tel)
  return tel.substr(0, 3) + '****' + tel.substr(7)
}

/**
 * 驼峰转短划线
 * @param {string} str
 * @returns {string}
 */
export const getKebabCase = (str: string): string => {
  return str.replace(/[A-Z]/g, item => '-' + item.toLowerCase())
}

/**
 * 短划线转驼峰
 * @param {string} str
 * @returns {string}
 */
export const getCamelCase = (str: string): string => {
  return str.replace(/-([a-z])/g, (i, item) => item.toUpperCase())
}

/**
 * 全角转半角
 * @param {string} str
 * @returns {string}
 */
export const toCDB = (str: string): string => {
  let result = ''
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i)
    if (code >= 65281 && code <= 65374) {
      result += String.fromCharCode(str.charCodeAt(i) - 65248)
    } else if (code == 12288) {
      result += String.fromCharCode(str.charCodeAt(i) - 12288 + 32)
    } else {
      result += str.charAt(i)
    }
  }
  return result
}

/**
 * 半角转全角
 * @param {string} str
 * @returns {string}
 */
export const toDBC = (str: string): string => {
  let result = ''
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i)
    if (code >= 33 && code <= 126) {
      result += String.fromCharCode(str.charCodeAt(i) + 65248)
    } else if (code == 32) {
      result += String.fromCharCode(str.charCodeAt(i) + 12288 - 32)
    } else {
      result += str.charAt(i)
    }
  }
  return result
}

/**
 * 数字金额转大写
 * @param {number} n
 * @returns {string}
 */
export const digitUppercase = (n: number): string => {
  const fraction = ['角', '分']
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟']
  ]
  n = Math.abs(n)
  let s = ''
  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '')
  }
  s = s || '整'
  n = Math.floor(n)
  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = ''
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p
      n = Math.floor(n / 10)
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
  }
  return s
    .replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整')
}

/**
 * 数字转中文数字
 * @param {number} value
 * @returns {string}
 */
export const intToChinese = (value: number): string => {
  const str = String(value)
  const len = str.length - 1
  const idxs = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿']
  const num = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']

  return str.replace(/([1-9]|0+)/g, ($, $1, idx, full) => {
    let pos = 0
    if ($1[0] !== '0') {
      pos = len - idx
      if (idx == 0 && $1[0] == 1 && idxs[len - idx] == '十') {
        return idxs[len - idx]
      }
      return num[$1[0]] + idxs[len - idx]
    } else {
      const left = len - idx
      const right = len - idx + $1.length
      if (Math.floor(right / 4) - Math.floor(left / 4) > 0) {
        pos = left - (left % 4)
      }
      if (pos) {
        return idxs[pos] + num[$1[0]]
      } else if (idx + $1.length >= len) {
        return ''
      } else {
        return num[$1[0]]
      }
    }
  })
}

//#region 各种证件号码 校验规则

export const checkLicenseNumber = (value: string): boolean => {
  const reg = /^\d{17}$/
  return value ? reg.test(value) : false
}

/**
 * 校验身份证 18 | 15 位
 * 18位
 * 地区： [1-9]\d{5}
 * 年的前两位： (18|19|20|21|22|23|24|25|26|27|28|29|30|31|32|33|34|35|36|37|38|39|40)\            1800-4099
 * 年的后两位： \d{2}
 * 月份：((0[1-9])|10|11|12)
 * 天数： (([0-2][1-9])|10|20|30|31)      闰年不能禁止29+
 * 三位顺序码： \d{3}
 *
 *
 * 15位
 * 地区：[1-6][1-9]\d{5}
 * 年份只有后两位：\d{2}
 * 月份：((0[1-9])|10|11|12)
 * 天数：(([0-2][1-9])|10|20|30|31)      闰年不能禁止29+
 *
 * (^[a-zA-Z]{5,17}$)|(^[a-zA-Z0-9]{5,17}$) 护照
 *
 * (^[0-9]{8}$)|(^[0-9]{10}$) 台湾
 *
 * ^[HMhm]{1}([0-9]{10}|[0-9]{8})$  港澳
 *
 * ^[a-zA-Z0-9]{18}$ 信用代码
 *
 * @param {string} value
 * @returns {boolean}
 */
export const checkIDCardNo = (value: string): boolean => {
  return value ? idCardNoReg.test(value) : false
}

/**
 * 护照
 * @param {string} value
 * @returns {boolean}
 */
export const checkPassport = (value: string): boolean => {
  const reg = /^([a-zA-Z]{5,17})|([a-zA-Z0-9]{5,17})$/
  return value ? reg.test(value) : false
}

/**
 * 台湾
 * @param {string} value
 * @returns {boolean}
 */
export const checkTaiwan = (value: string): boolean => {
  const reg = /^([0-9]{8})|([0-9]{10})$/
  return value ? reg.test(value) : false
}

/**
 * 香港
 * @param {string} value
 * @returns {boolean}
 */
export const checkHongKong = (value: string): boolean => {
  const reg = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/
  return value ? reg.test(value) : false
}

/**
 * 信用代码18位
 * @param {string} value
 * @returns{boolean}
 */
export const checkCreditCode = (value: string): boolean => {
  const reg = /^[a-zA-Z0-9]{18}$/
  return value ? reg.test(value) : false
}

/**
 * 证件类型 ( 与后台 证件类型 id 一一对应)
 */
export type IdType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | null
/**
 * 检查证件类型格式组
 * @param {ProjectCliectIdCardTypeCode} type 1 身份证  2 信用代码  3  护照  4  港澳通行证  5  台湾通行证
 * @param {string} value
 * @returns {boolean}
 */
export const checkCertificateFormat = (value: string, type?: ProjectCliectIdCardTypeCode): boolean => {
  switch (type) {
    case ProjectCliectIdCardTypeCode.Id:
      return checkIDCardNo(value)
    case ProjectCliectIdCardTypeCode.CreditCode:
      return checkCreditCode(value)
    case ProjectCliectIdCardTypeCode.PassPort:
      return checkPassport(value)
    case ProjectCliectIdCardTypeCode.PassCheck:
      return checkHongKong(value)
    case ProjectCliectIdCardTypeCode.TaiwanCertificate:
      return checkTaiwan(value)
    default:
      return true
  }
}

export const checkCertificateFormatForName = (value: string, type?: string): boolean => {
  switch (type) {
    case '身份证':
      return checkIDCardNo(value)
    case '统一社会信用代码':
      return checkCreditCode(value)
    case '护照':
      return checkPassport(value)
    case '港澳通行证':
      return checkHongKong(value)
    case '台胞证':
      return checkTaiwan(value)
    default:
      return true
  }
}

//#endregion

/**
 * 验证密码规则
 * @param {number} type
 * @param {string} value
 * @returns {boolean}
 */
export const checkPassword = (type: number, value: string): boolean => {
  switch (type) {
    case 1: //密码至少包含 数字和英文，长度6-20  "^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$"
      return passWordReg2.test(value)
    case 2: //密码包含 数字,英文,字符中的两种以上，长度6-20   "^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,20}$"
      return passWordReg1.test(value)
    case 3: // 至少包含数字跟字母，可以有字符 (?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,20}$
      return passWordReg.test(value)
    default:
      return true
  }
}

/**
 * 是否包含中文
 * @param {string} value
 * @returns {boolean}
 */
export const haveCNChars = (value: string): boolean => {
  return /[\u4e00-\u9fa5]/.test(value)
}

/**
 * 是否为正确邮编
 * @param {string} value
 * @returns
 */
export const isPostCode = (value: string): boolean => {
  return /^[1-9][0-9]{5}$/.test(value.toString())
}

/**
 * 是否位邮箱
 * @param {string} value
 * @returns
 */
export const isEmail = (value: string): boolean => {
  return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)
}

/**
 * 是否为中国大陆手机号
 * @param {string} value
 * @returns {boolean}
 */
export const isTel = (value: string): boolean => {
  return phoneNumberReg.test(value.toString())
}

/**
 * 是否有emoji表情
 * @param {string} value
 * @returns {boolean}
 */
export const isEmojiCharacter = (value: string): boolean => {
  value = String(value)
  for (let i = 0; i < value.length; i++) {
    const hs = value.charCodeAt(i)
    if (0xd800 <= hs && hs <= 0xdbff) {
      if (value.length > 1) {
        const ls = value.charCodeAt(i + 1)
        const uc = (hs - 0xd800) * 0x400 + (ls - 0xdc00) + 0x10000
        if (0x1d000 <= uc && uc <= 0x1f77f) {
          return true
        }
      }
    } else if (value.length > 1) {
      const ls = value.charCodeAt(i + 1)
      if (ls == 0x20e3) {
        return true
      }
    } else {
      if (0x2100 <= hs && hs <= 0x27ff) {
        return true
      } else if (0x2b05 <= hs && hs <= 0x2b07) {
        return true
      } else if (0x2934 <= hs && hs <= 0x2935) {
        return true
      } else if (0x3297 <= hs && hs <= 0x3299) {
        return true
      } else if (hs == 0xa9 || hs == 0xae || hs == 0x303d || hs == 0x3030 || hs == 0x2b55 || hs == 0x2b1c || hs == 0x2b1b || hs == 0x2b50) {
        return true
      }
    }
  }
  return false
}
