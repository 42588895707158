import { defineStore } from 'pinia'

import { getRegionAllTree } from '@/api/system/region'

import type { RegionItem } from '@/types/system/region'

export const SystemRegion = defineStore('SystemRegion', {
  state: () => {
    return {
      tree: [] as Array<RegionItem>
    }
  },
  getters: {
    regionTree: state => state.tree as Array<RegionItem>
  },
  actions: {
    async getRegionTree(idList: Array<number> = []) {
      try {
        const res = await getRegionAllTree(idList)
        const { data, succeeded } = res
        succeeded ? (this.tree = data) : ''
        return res
      } catch (error) {}
    }
  }
})
