/** 其他页面路由,eg:错误页等 */
import { RouteRecordRaw } from 'vue-router'
import RouteView from '@/layouts/routeView.vue'
/**
 * 错误页
 */
export const Error: RouteRecordRaw = {
  path: '/error',
  name: 'error',
  meta: { title: 'error', icon: 'WarningOutlined', hideInMenu: true, hideChildrenInMenu: true, fullPath: '/error' },
  component: RouteView,
  redirect: '/error/403',
  children: [
    {
      path: '403',
      name: 'error-403',
      meta: { icon: 'HistoryOutlined', title: '403', fullPath: '/error/403' },
      component: () => import(/* webpackChunkName: "error" */ '@/views/error/403.vue')
    },
    {
      path: '404',
      name: 'error-404',
      meta: { icon: 'HistoryOutlined', title: '404', fullPath: '/error/404' },
      component: () => import(/* webpackChunkName: "error" */ '@/views/error/404.vue')
    },
    {
      path: '500',
      name: 'error-500',
      meta: { icon: 'HistoryOutlined', title: '500', fullPath: '/error/500' },
      component: () => import(/* webpackChunkName: "error" */ '@/views/error/500.vue')
    }
  ]
}

/**
 * 结果页
 */
export const Result: RouteRecordRaw = {
  name: 'result',
  path: '/result',
  meta: { title: 'result', icon: 'CheckCircleOutlined', hideInMenu: true, hideChildrenInMenu: true, fullPath: '/result' },
  component: RouteView,
  redirect: '/result/success',
  children: [
    {
      name: 'result-success',
      path: 'success',
      meta: { icon: 'HistoryOutlined', title: 'success', fullPath: '/result/success' },
      component: () => import(/* webpackChunkName: "result" */ '@/views/result/success.vue')
    },
    {
      name: 'result-fail',
      path: 'fail',
      meta: { icon: 'HistoryOutlined', title: 'fail', fullPath: '/result/fail' },
      component: () => import(/* webpackChunkName: "result" */ '@/views/result/fail.vue')
    }
  ]
}

export const OutSideUse: RouteRecordRaw = {
  name: 'outside',
  path: '/outside',
  meta: { title: '收款二维码', fullPath: '/outside' },
  component: RouteView,
  children: [
    {
      path: 'payment/:contractId(\\d+)/:paymentway',
      name: 'outside-payment',
      meta: { icon: 'HistoryOutlined', title: '案件付款信息', fullPath: '/outside/payment' },
      component: () => import('@/views/outSide/payment.vue')
    },
    {
      path: 'paymentresult',
      name: 'outside-payment-result',
      meta: { icon: 'HistoryOutlined', title: '付款结果通知', fullPath: '/outside/paymentresult' },
      component: () => import('@/views/outSide/paymentResult.vue')
    },
    {
      path: 'aligpt',
      name: 'outside-aligpt',
      meta: { icon: 'oa_robot', title: '阿里通义千问', fullPath: '/outside/aligpt' },
      component: () => import('@/views/outSide/aligpt.vue')
    }
  ]
}
