import { defineStore } from 'pinia'
import { useRoute } from 'vue-router'

import { Account } from './account'
import { Permission } from './permission'
import { AppFunction } from './appFunction/index'
import { SystemCaseReason } from './system/caseReason'
import { SystemClientSource } from './system/clientSource'
import { SystemClientType } from './system/clientType'
// import { SystemContractSource } from './system/contractSource'
import { SystemContractType } from './system/contractType'
import { SystemProjectNormalType } from './system/projectNormalType'
import { SystemIndustry } from './system/industry'
import { SystemInvoiceType } from './system/invoiceType'
import { SystemPaymentMethod } from './system/paymentMethod'
import { SystemPaymentType } from './system/paymentType'
import { SystemRegion } from './system/region'
import { SystemComplaintSource } from './system/complaintSource'
import { SystemOrgainzation } from './system/organization'
import { SystemProjectSource } from './system/projectSource'

export const AppStores = defineStore('AppStores', {
  state: () => {
    return {
      loading: false,
      defaultData: [] as Array<string>, //  已初始化的默认数据
      menuCollapsed: false, // 导航菜单展开\收缩状态
      sideBarWidth: 220, // 左侧导航展开宽度
      sideBarCollapsedWidth: 64, // 左侧导航收缩宽度
      // globalHeaderWidth: '', // 顶部 header 宽度
      globalHeaderHeight: 52, // 顶部 header 高度
      isMobile: false // 是否是手机打开
    }
  },
  getters: {
    globalHeaderWidth(state) {
      return (layout: string) => {
        // return state.menuCollapsed ? `calc(100% - ${state.sideBarCollapsedWidth}px)` : `calc(100% - ${state.sideBarWidth}px)`
        return state.isMobile || layout === 'user' ? '100%' : state.menuCollapsed ? `calc(100% - ${state.sideBarCollapsedWidth}px)` : `calc(100% - ${state.sideBarWidth}px)`
      }
    }
  },
  actions: {
    async getAppDfaultMustData() {
      try {
        await Account().getAccoutInfo() //获取当前登录用户信息
        await Account().getRoleRange() // 获取当前登录用户角色数据范围
        await Permission().getUserPermission() // 获取当前登录用户所有权限(功能权限) 生成路由时必须使用此数据
        await Permission().getUserRangeList() //  // 获取当前登录用户所有权限(数据权限)
      } catch (error) {}
    },

    async getAppDefaultBaseData() {
      try {
        const route = useRoute()

        await AppFunction().appLawfirmParams(Account().userLawfirmId) // 获取当前登录用户功能菜单列表
        await AppFunction().appUserParams() //获取当前用户配置
        // 根据当前访问地址,页面数据的基础数据项是否已有基础数据,如果没有,则请求后台接口获取数据,并存入默认数据数组以便后续判断是否已有此项数据

        // 客户来源
        if (route.fullPath.includes('/case/create') || route.fullPath.includes('/case/update') || route.fullPath.includes('/client/create') || route.fullPath.includes('/client/update')) {
          !this.defaultData.includes('clientSource') ? (await SystemClientSource().getList(), this.defaultData.push('clientSource')) : ''
        }

        // 客户类型
        if (
          route.fullPath.includes('/client/index') ||
          route.fullPath.includes('/client/create') ||
          route.fullPath.includes('/client/update') ||
          route.fullPath.includes('/case/create') ||
          route.fullPath.includes('/case/update') ||
          route.fullPath.includes('/case/check') ||
          route.fullPath.includes('/case/pass')
        ) {
          !this.defaultData.includes('clientType') ? (await SystemClientType().getList({ pageIndex: 1, pageSize: 100 }), this.defaultData.push('clientType')) : ''
        }

        // 合同类型
        if (route.fullPath.includes('/marketcreate') || route.fullPath.includes('/marketupdate') || route.fullPath.includes('/case') || route.fullPath.includes('/letter/guide')) {
          !this.defaultData.includes('contractType') ? (await SystemContractType().getList(), this.defaultData.push('contractType')) : ''
        }

        // // 合同来源
        // if (route.fullPath.includes('/case/create') || route.fullPath.includes('/case/update')) {
        //   !this.defaultData.includes('contractSource') ? (await SystemContractSource().getList(), this.defaultData.push('contractSource')) : ''
        // }

        // 案由
        if (route.fullPath.includes('/case/create') || route.fullPath.includes('/case/update')) {
          !this.defaultData.includes('caseReason') ? (await SystemCaseReason().getCaseReasoTree(), this.defaultData.push('caseReason')) : ''
        }

        // 行业
        if (route.fullPath.includes('/client/create') || route.fullPath.includes('/client/update') || route.fullPath.includes('/case/create') || route.fullPath.includes('/case/update')) {
          !this.defaultData.includes('industry') ? (await SystemIndustry().getIndustryTree(), this.defaultData.push('industry')) : ''
        }

        // 受理机构
        if (route.fullPath.includes('/case/create') || route.fullPath.includes('/case/update')) {
          !this.defaultData.includes('organization') ? (await SystemOrgainzation().getOrganizationData(), this.defaultData.push('organization')) : ''
        }

        // 收费方式
        if (route.fullPath.includes('/case/create') || route.fullPath.includes('/case/update')) {
          !this.defaultData.includes('paymentMethod') ? (await SystemPaymentMethod().getPaymentMethodTree([]), this.defaultData.push('paymentMethod')) : ''
        }

        // 地区
        if (route.fullPath.includes('/client/create') || route.fullPath.includes('/client/update') || route.fullPath.includes('/case/update') || route.fullPath.includes('/case/create')) {
          !this.defaultData.includes('region') ? (await SystemRegion().getRegionTree(), this.defaultData.push('region')) : ''
        }

        // 获取发票类型
        if (
          route.fullPath.includes('/finance/invoice') ||
          route.fullPath.includes('/invoicecreate') ||
          route.fullPath.includes('/invoice/create') ||
          route.fullPath.includes('/history/invoice') ||
          route.fullPath.includes('/supplement/invoice')
        ) {
          !this.defaultData.includes('invoiceType') ? (await SystemInvoiceType().getList(), this.defaultData.push('invoiceType')) : ''
        }

        // 获取缴费支付类型
        if (
          route.fullPath.includes('/case/payment/create') ||
          route.fullPath.includes('/finance/payment/update') ||
          route.fullPath.includes('/paymentcreate') ||
          route.fullPath.includes('/payment/create') ||
          route.fullPath.includes('/history/payment') ||
          route.fullPath.includes('/supplement/payment')
        ) {
          !this.defaultData.includes('PaymentType') ? (await SystemPaymentType().getList(), this.defaultData.push('PaymentType')) : ''
        }

        // 获取投诉来源
        if (route.fullPath.includes('/complaint')) {
          !this.defaultData.includes('complaintSource') ? (await SystemComplaintSource().getList(), this.defaultData.push('complaintSource')) : ''
        }

        if (route.fullPath.includes('/project')) {
          !this.defaultData.includes('clientSource') ? (await SystemClientSource().getList(), this.defaultData.push('clientSource')) : ''
          !this.defaultData.includes('clientType') ? (await SystemClientType().getList({ pageIndex: 1, pageSize: 100 }), this.defaultData.push('clientType')) : ''
          !this.defaultData.includes('projectNormalType') ? (await SystemProjectNormalType().getList(), this.defaultData.push('projectNormalType')) : ''
          !this.defaultData.includes('projectSource') ? (await SystemProjectSource().getList(), this.defaultData.push('projectSource')) : ''
          !this.defaultData.includes('caseReason') ? (await SystemCaseReason().getCaseReasoTree(), this.defaultData.push('caseReason')) : ''
          !this.defaultData.includes('industry') ? (await SystemIndustry().getIndustryTree(), this.defaultData.push('industry')) : ''
          !this.defaultData.includes('organization') ? (await SystemOrgainzation().getOrganizationData(), this.defaultData.push('organization')) : ''
          !this.defaultData.includes('paymentMethod') ? (await SystemPaymentMethod().getPaymentMethodTree([]), this.defaultData.push('paymentMethod')) : ''
          !this.defaultData.includes('region') ? (await SystemRegion().getRegionTree(), this.defaultData.push('region')) : ''
        }
        if (route.fullPath.includes('/letterproject')) {
          !this.defaultData.includes('projectNormalType') ? (await SystemProjectNormalType().getList(), this.defaultData.push('projectNormalType')) : ''
        }
      } catch (error) {}
    }
  }
})
