import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Administration: RouteRecordRaw = {
  path: 'administration',
  name: 'manage-administration',
  meta: { title: '行政管理', icon: 'oa_xingzheng', fullPath: '/manage/administration' },
  component: RouteView,
  redirect: '/manage/administration/workStation',
  children: [
    {
      path: 'workStation',
      name: 'manage-administration-workStation',
      meta: { icon: 'oa_gongwei', role: 'Admin.Admin.WorkStation', title: '工位管理', fullPath: '/manage/administration/workStation' },
      component: (): Component => import('@/views/manage/administration/workStation/index.vue')
    },
    {
      path: 'workstationorderlist/:id',
      name: 'manage-administration-workstation-orderlist',
      meta: { icon: 'oa_gongwei', role: 'Admin.Admin.WorkStation', title: '工位订单', hideInMenu: true, fullPath: '/manage/administration/workstationorderlist' },
      component: (): Component => import('@/views/manage/administration/workStation/orderList.vue')
    },
    {
      path: 'adminCostRecord',
      name: 'manage-administration-adminCostRecord',
      meta: { icon: 'oa_yewubumen', role: 'Admin.Admin.Charge', title: '行政扣费管理', fullPath: '/manage/administration/adminCostRecord' },
      component: (): Component => import('@/views/manage/administration/adminCostRecord/index.vue')
    },
    {
      path: 'sign',
      name: 'manage-administration-sign',
      meta: { icon: 'oa_sign', role: 'Admin.Admin.Sign', title: '用章管理', fullPath: '/manage/administration/sign' },
      component: (): Component => import('@/views/manage/administration/sign/index.vue')
    },
    {
      path: 'sign/info/:id',
      name: 'manage-administration-info',
      meta: { icon: 'oa_sign', role: 'Admin.Admin.Sign', hideInMenu: true, title: '用章管理详情', fullPath: '/manage/administration/info' },
      component: (): Component => import('@/views/manage/administration/sign/info.vue')
    },
    {
      path: 'addressBook',
      name: 'manage-administration-addressBook',
      meta: { icon: 'oa_addressbook', title: '通讯录', fullPath: '/manage/administration/addressBook' },
      component: (): Component => import('@/views/administration/addressBook.vue')
    },
    {
      path: 'notice',
      name: 'manage-administration-notice',
      meta: { icon: 'oa_notice', title: '公告', fullPath: '/manage/administration/notice' },
      component: (): Component => import('@/views/manage/administration/notice/index.vue')
    },
    {
      path: 'notice/create',
      name: 'manage-administration-notice-create',
      meta: { icon: 'oa_notice', title: '添加公告', role: 'Admin.Admin.Notice', hideInMenu: true, fullPath: '/manage/administration/notice/create' },
      component: (): Component => import('@/views/manage/administration/notice/form.vue')
    },
    {
      path: 'notice/update/:id(\\d+)',
      name: 'manage-administration-notice-update',
      props: true,
      meta: { icon: 'oa_notice', title: '编辑公告', role: 'Admin.Admin.Notice', hideInMenu: true, fullPath: '/manage/administration/notice/update' },
      component: (): Component => import('@/views/manage/administration/notice/form.vue')
    }
  ]
}
