<!--
  此组件 继承 ant-design-vue  upload 上传组件 所有属性
  参考 https://next.antdv.com/components/upload-cn
  accept: 接受上传的文件类型 传入 以逗号为分割符的文件后缀名 文件后缀名 需要带点  eg: .png,.jpg
  文件列表 在调用时 已 v-model:file-list="state.fileList"  为属性 可以直接获取和传入
-->

<template>
  <a-upload v-bind="$attrs" :accept="props.accept" :before-upload="customBeforeUpload" :custom-request="customRequest" @change="customChange" @preview="customPreview" @remove="customerRemove">
    <a-button v-if="attrs['list-type'] !== 'picture-card'" :loading="state.loading"><svg-icon name="oa_plus" /> {{ props.buttonText }}</a-button>
    <div v-else-if="props.maxCount ? (attrs['file-list'] as Array<any>).length < props.maxCount : true" style="font-size: 16px">
      <svg-icon name="oa_plus" />
      <div class="ant-upload-text">{{ props.buttonText }}</div>
    </div>

    <!-- <slot /> -->
    <template v-if="attrs['list-type'] !== 'picture-card'" #itemRender="{ file, actions }">
      <div class="file_item">
        <a-flex :gap="24">
          <a-input v-model:value="file.name" :size="'small'" :bordered="false" style="width: 400px" />
          <a :href="file?.response?.url || file?.url" target="_blank" style="width: 80px"><svg-icon name="oa_eye" />查看</a>
          <template v-if="props.fileTypeList?.length">
            <a-select v-model:value="file.fileType" size="small" style="width: 300px" placeholder="文件类型" allowClear>
              <template v-for="item in props.fileTypeList" :key="item.id">
                <a-select-option :value="item.id">{{ item.name }}</a-select-option>
              </template>
            </a-select>
          </template>
          <a-input v-model:value="file.remarks" :size="'small'" :bordered="false" placeholder="备注" style="width: 300px" />
          <a href="javascript:;" :style="{ color: 'red' }" @click="actions.remove" style="width: 80px"><svg-icon name="oa_delete" />删除</a>
        </a-flex>
      </div>
    </template>
  </a-upload>
</template>

<script setup lang="ts">
  import { computed, reactive, useAttrs } from 'vue'
  import { notification, Upload } from 'ant-design-vue'

  import request from '@/utils/request'

  import { GetOssSignature } from '@/api/system/oss'

  import type { FileItem, OssFile } from '@/types/file'

  defineOptions({ name: 'OSS' })
  const attrs = useAttrs()

  const props = defineProps({
    maxCount: {
      // 文件允许数量
      type: Number,
      default: undefined
    },
    maxSize: {
      // 单文件大小(MB)
      type: Number,
      default: 500
    },
    accept: {
      // 允许类型
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: '上传'
    },
    // 文件上传类型,对应文件上传目录 id
    type: {
      type: Number,
      default: undefined
    },
    fileTypeList: {
      type: Array<any>
    }
  })

  // 单文件上传成功回调父级组件事件 用于 tinymce 富文本编辑器
  const emit = defineEmits(['singleFileUpload'])

  const state = reactive({
    loading: false
  }) as {
    loading: boolean
  }

  // 是否超出个数
  const checkMaxLength = (fileList: Array<File>): boolean => {
    // 文件个数 < 最大个数
    // @ts-expect-error 动态属性获取
    const allow = props.maxCount ? fileList.length + attrs['file-list'].length <= props.maxCount : true
    allow ? '' : notification.error({ message: `此处最多允许上传${props.maxCount}个文件,您已超出文件个数限制.` })
    return allow
  }

  // 是否超出大小
  const checkMaxSize = (file: File): boolean => {
    // 文件大小 < 最大大小
    const allow = props.maxSize ? file.size < props.maxSize * 1024 * 1024 : true
    allow ? '' : notification.error({ message: `单文件大小不允许超过${props.maxSize}M.您上传文件已超出,请进行压缩` })
    return allow
  }

  // 是否为允许文件类型
  const checkFileType = (file: File): boolean => {
    // 文件类型不包含
    const acceptList = props.accept !== '' ? props.accept.replace(/\./g, '').split(',') : []
    const filetype = file.name.split('.').pop() || ''
    const allow = acceptList.length ? acceptList.indexOf(filetype) !== -1 : true
    return allow
  }

  /**
   * 自定义上传验证
   * 文件上传之前执行
   * 可用于验证文件大小\文件类型\文件名称等
   * @param {File} file 文件 当前文件
   * @param {Array<File>} fileList 文件列表包含全部文件
   */
  const customBeforeUpload = (file: File, fileList: Array<File>) => {
    return new Promise<File>((resolve, _reject) => {
      state.loading = true
      checkMaxLength(fileList) && checkMaxSize(file) && checkFileType(file) ? resolve(file) : Upload.LIST_IGNORE
      state.loading = false
    })
  }

  /**
   * 根据文件类型确定请求头
   */
  type XOssObjectAcl = 'default' | 'private' | 'public-read' | 'public-read-write'
  type header = {
    contentType: string
    'x-oss-object-acl'?: XOssObjectAcl
  }

  const getHeaders = () => {
    const header: header = {
      contentType: 'multipart/form-data' // 发送请求内容数据类型
    }
    attrs.name === 'avatar' ? (header['x-oss-object-acl'] = 'public-read') : ''
    return header
  }

  const fileType = computed((): number | string => (props.type as number) || (attrs.name as string))
  /**
   * 自定义上传
   */
  const customRequest = (file: OssFile) => {
    const fileData = { fileName: file.file.name, fileType: fileType.value }
    // 签名
    GetOssSignature(fileData).then(respose => {
      const { data: res, succeeded } = respose
      if (succeeded) {
        const data = new FormData()
        for (const item in res) {
          // @ts-expect-error 动态属性获取
          data.append(item, res[item])
        }
        data.append('File', file.file)

        const config = {
          headers: getHeaders(),
          // 进度条
          onUploadProgress: (progressEvent: any) => {
            const complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0 // + '%'
            // @ts-expect-error 动态属性获取
            file.onProgress({ percent: complete }) // 手动调用文件上传进度条
          }
        }
        // 上传
        request.post(res.host, data, config).then(response => {
          // @ts-expect-error 动态属性获取
          file.onSuccess(response.data, file.file)
        })
      }
    })
  }

  interface FileInfo {
    file: FileItem
    fileList: Array<FileItem>
  }

  const customChange = (option: FileInfo) => {
    const { file, fileList } = option
    file.status === 'done' ? emit('singleFileUpload', file) : ''

    fileList.forEach((item: FileItem) => {
      if (item.status === 'done' && item.response) {
        item.url = item.response.fileUrl
        item.thumbUrl = item.response.fileUrl
      }
    })
  }

  /**
   * 文件预览,浏览器支持预览新标签打开,不支持预览进行下载
   * @param {FileItem} file
   */
  const customPreview = (file: FileItem) => {
    open(file.response ? file.response.fileUrl : file.url || file.thumbUrl, '_blank')
  }

  const customerRemove = (file: FileItem) => {
    notification.info({ message: `${file.name} 删除成功` })
  }
</script>
<style lang="less" scoped>
  .ant-upload-list {
    .ant-upload-list-item-container {
      &:first-child {
        margin-top: 10px;
      }

      margin-bottom: 10px;
    }
  }

  .file_item {
    margin-top: 10px;
    margin-bottom: 10px;

    .ant-input {
      border-bottom: 1px solid #d9d9d9;
    }

    a {
      svg {
        margin-right: 8px;
      }
    }
  }
</style>
