import request from '@/utils/request'
import type { PageResultData, RequestResult } from '../typing'
import type { CheckExist } from '@/types/typing'
import type { ProjectSourceItem, ProjectSourceCreateOrUpdate, ProjectSourceParams } from '@/types/system/projectSource'

/**
 * 获取当前用户所在律所案件来源
 * @returns {RequestResult<PageResultData<ProjectSourceItem>>}
 */
export async function getProjectSource() {
  return request.get<null, RequestResult<Array<ProjectSourceItem>>>('/api/ProjectSource/getList')
}

/**
 * 案件来源
 * 获取案件来源列表
 * @param {ProjectSourceParams} ProjectSourceParams
 * @returns {PageResultData<ProjectSourceItem>}
 */
export async function getProjectSourceList(ProjectSourceParams: ProjectSourceParams) {
  return request.get<ProjectSourceParams, RequestResult<PageResultData<ProjectSourceItem>>>('/api/ProjectSource/GetPagedList', { params: ProjectSourceParams })
}

/**
 * 根据ID获取案件来源详情
 * @param {number} id
 * @returns {RequestResult<ProjectSourceItem>}
 */
export async function getProjectSourceInfo(id: number) {
  return request.get<number, RequestResult<ProjectSourceItem>>(`/api/ProjectSource/GetById/${id}`)
}

/**
 * 检查是否重复
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<ProjectSourceItem>}
 */
export async function checkProjectSourceName(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/ProjectSource/CheckExist`, CheckExist)
}

/**
 * 创建案件来源
 * @param {ProjectSourceCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function createProjectSource(data: ProjectSourceCreateOrUpdate) {
  return request.post<ProjectSourceCreateOrUpdate, RequestResult<null>>(`/api/ProjectSource/create`, data)
}

/**
 * 编辑案件来源
 * @param {ProjectSourceCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function updateProjectSource(data: ProjectSourceCreateOrUpdate) {
  return request.put<ProjectSourceCreateOrUpdate, RequestResult<null>>(`/api/ProjectSource/update`, data)
}

/**
 * 删除案件来源
 * @param {ProjectSourceItem} data
 * @returns {RequestResult}
 */
export async function deleteProjectSource(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/ProjectSource/delete/${id}`)
}

/**
 * 软删除案件来源
 * @param {ProjectSourceItem} data
 * @returns {RequestResult}
 */
export async function deleteProjectSourceSoft(id: number) {
  return request.post<number, RequestResult<null>>(`/api/ProjectSource/SoftDelete/${id}`)
}
