import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Finance: RouteRecordRaw = {
  path: 'financeproject',
  name: 'financeproject',
  meta: { title: '财务管理(项目)', icon: 'oa_zhang', fullPath: '/financeproject' },
  component: RouteView,
  redirect: 'financeproject/invoce',
  children: [
    {
      path: 'ledger',
      name: 'financeproject-ledger',
      meta: { icon: 'oa_invoice', title: '我的台账', fullPath: '/financeproject/ledger' },
      component: (): Component => import('@/views/projectFinance/ledger.vue')
    },
    {
      path: 'projectinfo/:projectId(\\d+)/:projectTypeCode',
      name: 'financeproject-projectinfo',
      meta: { icon: 'oa_invoice', hideInMenu: true, title: '案件详情', fullPath: '/financeproject/projectinfo' },
      component: (): Component => import('@/views/projectFinance/projectInfo.vue')
    },
    {
      path: 'invoice',
      name: 'financeproject-invoice',
      meta: { icon: 'oa_invoice', title: '开票', fullPath: '/financeproject/invoice' },
      component: (): Component => import('@/views/projectFinance/invoice/index.vue')
    },
    {
      path: 'invoice/update/:projectId(\\d+)/:projectTypeCode/:invoiceid',
      name: 'financeproject-invoice-update',
      meta: { icon: 'oa_invoice', hideInMenu: true, title: '编辑开票', fullPath: '/financeproject/invoice/update' },
      component: (): Component => import('@/views/projectFinance/invoice/update.vue')
    },
    {
      path: 'payment',
      name: 'financeproject-payment',
      meta: { icon: 'oa_pay', title: '缴费', fullPath: '/financeproject/payment' },
      component: (): Component => import('@/views/projectFinance/payment/index.vue')
    },
    {
      path: 'payment/update/:projectId(\\d+)/:projectTypeCode/:paymentid',
      name: 'financeproject-payment-update',
      meta: { icon: 'oa_pay', hideInMenu: true, title: '编辑缴费', fullPath: '/financeproject/payment/update' },
      component: (): Component => import('@/views/projectFinance/payment/update.vue')
    },
    {
      path: 'refund',
      name: 'financeproject-refund',
      meta: { icon: 'oa_refund', title: '退款', fullPath: '/financeproject/refund' },
      component: (): Component => import('@/views/projectFinance/refund/index.vue')
    },
    {
      path: 'refund/update/:projectId(\\d+)/:projectTypeCode/:refundid',
      name: 'financeproject-refund-update',
      meta: { icon: 'oa_refund', hideInMenu: true, title: '编辑退款', fullPath: '/financeproject/refund/update' },
      component: (): Component => import('@/views/projectFinance/refund/update.vue')
    },
    // 调账
    {
      path: 'transfer',
      name: 'financeproject-transfer',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Transfer', title: '调账', fullPath: '/financeproject/transfer' },
      component: (): Component => import('@/views/projectFinance/transfer/index.vue')
    }
  ]
}
