import { defineStore } from 'pinia'

import { getProjectSource } from '@/api/system/projectSource'

import type { ProjectSourceItem } from '@/types/system/projectSource'

export const SystemProjectSource = defineStore('SystemProjectSource', {
  state: () => {
    return {
      list: [] as Array<ProjectSourceItem>
    }
  },
  getters: {
    ProjectSourceList: state => state.list as Array<ProjectSourceItem>
  },
  actions: {
    async getList() {
      try {
        const res = await getProjectSource()
        const { data, succeeded } = res
        succeeded ? (this.list = data) : ''
        return res
      } catch (error) {}
    }
  }
})
