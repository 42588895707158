import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Letter: RouteRecordRaw = {
  path: 'letterproject',
  name: 'letterproject',
  meta: { title: '函书申领(项目)', hideInMenu: true, hideChildrenInMenu: true, icon: 'oa_letter', fullPath: '/letterproject' },
  component: RouteView,
  redirect: '/letterproject/index',
  children: [
    {
      path: 'index',
      name: 'letterproject-index',
      meta: { icon: 'oa_letter', title: '函书申领列表', fullPath: '/letterproject/index' },
      component: (): Component => import('@/views/projectLetter/index.vue')
    },
    {
      path: 'guide/:projectId(\\d+)/:caseTypeId(\\d+)/:projectTypeCode',
      name: 'letterproject-guide',
      meta: { icon: 'oa_letter', title: '函书申领', fullPath: '/letterproject/guide' },
      component: (): Component => import('@/views/projectLetter/guide.vue')
    },
    {
      path: 'apply/:letterType/:projectId(\\d+)/:projectTypeCode/:region?',
      name: 'letterproject-apply',
      meta: { icon: 'oa_letter', hideInMenu: true, title: '函书申领', fullPath: '/letterproject/apply' },
      component: (): Component => import('@/views/projectLetter/apply.vue')
    }
  ]
}
