import request from '@/utils/request'
import type { PageResultData, RequestResult } from '../typing'
import {
  UserItem,
  UserParams,
  ChangeUserPasswordParams,
  LeaveCaseItem,
  LeaveCaseParams,
  LeaveFinanceItem,
  LeaveFinanceParams,
  ChangeOrganizationParams,
  UserConfig
} from '@/stores/modules/user/typing'
import { CheckExist } from '@/stores/typing'
import type { ChangeUserRoleForm, SimpleUserCreate, SimpleUserUpdate, UserInfo } from '@/types/user'

/**
 * 获取人员(用户)列表
 * @param {UserParams} UserParams
 * @returns {PageResultData<UserItem>}
 */
export async function getUserList(UserParams: UserParams) {
  return request.get<UserParams, RequestResult<PageResultData<UserItem>>>('/api/User/GetPagedList', { params: UserParams })
}

/**
 * 通讯录
 * @param {UserParams} query
 * @returns {RequestResult<PageResultData<UserItem>>}
 */
export async function getUserContactList(query: UserParams) {
  return request.get<UserParams, RequestResult<PageResultData<Array<UserItem>>>>(`/api/User/GetContactPagedList`, { params: query })
}

/**
 * 远程过滤自动补全
 * @param {UserParams} params
 * @returns {RequestResult<PageResultData<UserItem>>}
 */
export async function getUserAutoComplateList(params: UserParams) {
  return request.get<UserParams, RequestResult<PageResultData<Array<UserItem>>>>(`/api/User/GetUserListForAutoComplate`, { params })
}

/**
 * 获取当前用户可选对应岗位
 * @returns {RequestResult<any>}
 */
export async function getUserFullRange() {
  return request.get<null, RequestResult<any>>('/api/Permission/GetUserFullRange')
}

//#region 快速 添加\编辑 用户

/**
 * 检测是否存在重复的用户名
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<boolean>}
 */
export async function checkUserName(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/User/CheckUserName`, CheckExist)
}

/**
 * 检测是否存在重复的身份证号码
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<boolean>}
 */
export async function checkUserIdCard(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/User/CheckIdCard`, CheckExist)
}

/**
 * 检测是否存在重复的手机号
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<UserItem>}
 */
export async function checkUserPhoneNumber(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/User/CheckPhoneNumber`, CheckExist)
}

/**
 * 检测是否存在重复的邮箱地址
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<UserTypeItem>}
 */
export async function checkUserEmail(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/User/CheckEmail`, CheckExist)
}

/**
 * 检测是否存在重复的执业证号码
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<UserTypeItem>}
 */
export async function checkUserLicenseNumber(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/User/CheckLicenseNumber`, CheckExist)
}

/**
 * 检测是否存在重复的工号
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<UserTypeItem>}
 */
export async function checkUserJobNumber(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/User/CheckJobNumber`, CheckExist)
}

/**
 * 快速创建用户
 * @param {SimpleUserCreate} data
 * @returns {RequestResult}
 */
export async function createUserSimple(data: SimpleUserCreate) {
  return request.post<SimpleUserCreate, RequestResult<any>>(`/api/User/SimpleCreate`, data)
}

/**
 * 编辑用户
 * * @param {SimpleUserUpdate} updateData
 * @returns {RequestResult<any>}
 */
export async function updateUser(updateData: SimpleUserUpdate) {
  return request.put<SimpleUserUpdate, RequestResult<any>>('/api/User/Update', updateData)
}

//#endregion

/**
 * 获取用户详情
 *  @param {number} id
 * @returns {RequestResult<UserInfo>}
 */
export async function getUserInfo(id: number) {
  return request.get<number, RequestResult<UserInfo>>(`/api/User/GetById/${id}`)
}

//#region 删除

/**
 * 删除用户
 * @param {number} id
 * @returns {RequestResult<any>}
 */
export async function deleteUser(id: number) {
  return request.delete<number, RequestResult<any>>(`/api/User/Delete/${id}`)
}

/**
 * 软删除用户
 * @param {number} id
 * @returns {RequestResult<any>}
 */
export async function deleteUserSoft(id: number) {
  return request.post<number, RequestResult<any>>(`/api/User/SoftDelete/${id}`)
}

//#endregion

//#region 已离职相关操作

/**
 * 获取已离职人员列表
 * @param {UserParams} UserParams
 * @returns {PageResultData<UserItem>}
 */
export async function getLeaveUserList(UserParams: UserParams) {
  return request.get<UserParams, RequestResult<PageResultData<UserItem>>>('/api/User/GetSoftDeletePagedList', { params: UserParams })
}

/**
 * 恢复软删除用户
 * @param {number} id
 * @returns {RequestResult<any>}
 */
export async function recoveryUserSoft(id: number) {
  return request.post<number, RequestResult<any>>(`/api/User/RecoveryUser/${id}`)
}

//#endregion

//#region
/**
 * 用户列表修改密码
 * @param {ChangeUserParams} ChangeUserParams
 * @returns {RequestResult<any>}
 */
export async function changeUserPassword(ChangeUserParams: ChangeUserPasswordParams) {
  return request.post<ChangeUserPasswordParams, RequestResult<any>>(`/api/User/ChangeUserPassword`, ChangeUserParams)
}

/**
 * 用户调岗
 * @param {ChangeOrganizationParams} ChangeUserParams
 * @returns {RequestResult<any>}
 */
export async function changeOrganizationUnit(ChangeUserParams: ChangeOrganizationParams) {
  return request.post<ChangeOrganizationParams, RequestResult<any>>(`/api/User/ChangeOrganizationUnit`, ChangeUserParams)
}

/**
 * 修改用户角色
 * @param data
 * @returns
 */
export async function changeUserRole(data: ChangeUserRoleForm) {
  return request.put<ChangeUserRoleForm, RequestResult<any>>(`/api/User/UpdateUserRoles`, data)
}

//#endregion

/**
 * 获取离职人员相关案件列表
 * @param {LeaveCaseParams} LeaveCaseParams
 * @returns {PageResultData<LeaveCaseItem>}
 */
export async function getLeaveCaseList(LeaveCaseParams: LeaveCaseParams) {
  return request.get<LeaveCaseParams, RequestResult<PageResultData<LeaveCaseItem>>>('/api/User/GetCheckCasePagedList', { params: LeaveCaseParams })
}
/**
 * 获取离职人员财务未结清案件列表
 * @param {LeaveFinanceParams} LeaveFinanceParams
 * @returns {PageResultData<LeaveFinanceItem>}
 */
export async function getLeaveFinanceList(LeaveFinanceParams: LeaveFinanceParams) {
  return request.get<LeaveFinanceParams, RequestResult<PageResultData<LeaveFinanceItem>>>('/api/User/GetCheckFinancePagedList', { params: LeaveFinanceParams })
}

/**
 * 获取所属团队权限
 * @returns {RequestResult<UserConfig>}
 */
export async function getUserConfig() {
  return request.get<null, RequestResult<UserConfig>>(`/api/User/GetUserConfig`)
}
