import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import ManageLayout from '@/layouts/user.vue'
export const Demo: RouteRecordRaw = {
  path: '/demo',
  name: 'demo',
  meta: { title: '示例', icon: 'TableOutlined', fullPath: '/demo' },
  component: ManageLayout,
  redirect: '/demo/index',
  children: [
    {
      path: 'index',
      name: 'demo-index',
      meta: { icon: 'HistoryOutlined', title: '使用示例', fullPath: '/demo/index' },
      component: (): Component => import('@/views/demo/index.vue')
    },
    {
      path: 'bpmnjs',
      name: 'demo-bpmnjs',
      meta: { icon: 'HistoryOutlined', title: '流程图', fullPath: '/demo/bpmnjs' },
      component: (): Component => import('@/views/demo/bpmnjs.vue')
    },
    {
      path: 'create',
      name: 'demo-create',
      meta: { icon: 'HistoryOutlined', title: '打印示例', fullPath: '/demo/create' },
      component: (): Component => import('@/views/demo/print.vue')
    },
    {
      path: 'excelUpload',
      name: 'demo-excel-upload',
      meta: { icon: 'HistoryOutlined', title: 'excel导入', fullPath: '/demo/excelupload' },
      component: (): Component => import('@/views/demo/excelUpload.vue')
    },
    {
      path: 'excelExport',
      name: 'demo-excel-export',
      meta: { icon: 'HistoryOutlined', title: 'excel导入', fullPath: '/demo/excelexport' },
      component: (): Component => import('@/views/demo/excelExport.vue')
    },
    {
      path: 'infocardform',
      name: 'demo-infocardform',
      meta: { icon: 'HistoryOutlined', title: '信息卡', fullPath: '/demo/infocardform' },
      component: (): Component => import('@/views/demo/infoCardForm.vue')
    },
    {
      path: 'scorllSelect',
      name: 'demo-scorllSelect',
      meta: { icon: 'HistoryOutlined', title: '无限滚动', fullPath: '/demo/scorllSelect' },
      component: (): Component => import('@/views/demo/scorllSelect.vue')
    },
    {
      path: 'oss',
      name: 'demo-oss',
      meta: { icon: 'HistoryOutlined', title: '文件上传', fullPath: '/demo/oss' },
      component: (): Component => import('@/views/demo/oss.vue')
    },
    {
      path: 'edit',
      name: 'demo-edit',
      meta: { icon: 'HistoryOutlined', title: '富文本编辑器', fullPath: '/demo/edit' },
      component: (): Component => import('@/views/demo/edit.vue')
    },
    {
      path: 'time',
      name: 'demo-time',
      meta: { icon: 'HistoryOutlined', title: '时间格式', fullPath: '/demo/time' },
      component: (): Component => import('@/views/demo/time.vue')
    },
    {
      path: 'progress',
      name: 'demo-progress',
      meta: { icon: 'HistoryOutlined', title: '进度条', fullPath: '/demo/progress' },
      component: (): Component => import('@/views/demo/progress.vue')
    }
  ]
}
