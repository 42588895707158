import request from '@/utils/request'
import type { RequestResult } from '../typing'
import type { CheckExist } from '@/types/typing'
import type { ProjectNormalTypeCreateOrUpdate, ProjectNormalTypeItem } from '@/types/system/projectNormalType'

/**
 * 合同类型
 * 获取案件类型Tree列表
 * @returns {RequestResult<Array<ProjectNormalTypeItem>>}
 */
export async function getProjectNormalTypeAllTree() {
  return request.get<Array<number>, RequestResult<Array<ProjectNormalTypeItem>>>(`/api/CaseType/GetTreeList`)
}

/**
 * 获取案件类型指定节点 tree
 * @param {number} id
 * @returns {RequestResult<ProjectNormalTypeItem>}
 */
export async function getProjectNormalTypeNodeTree(id: number) {
  return request.get<number, RequestResult<ProjectNormalTypeItem>>(`/api/CaseType/GetTree/${id}`)
}

/**
 * 获取案件类型节点ID下子节点
 * @param {number} id
 * @returns {RequestResult<Array<ProjectNormalTypeItem>>}
 */
export async function getProjectNormalTypeChildTree(id: number) {
  return request.get<number, RequestResult<Array<ProjectNormalTypeItem>>>(`/api/CaseType/GetChildList/${id}`)
}

/**
 * 根据ID获取案件类型详情
 * @param {number} id
 * @returns {RequestResult<ProjectNormalTypeItem>}
 */
export async function getProjectNormalTypeInfo(id: number) {
  return request.get<number, RequestResult<ProjectNormalTypeItem>>(`/api/CaseType/GetById/${id}`)
}

/**
 * 检查是否重复
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<ProjectNormalTypeItem>}
 */
export async function checkProjectNormalTypeName(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/CaseType/CheckExist`, CheckExist)
}

/**
 * 创建案件类型
 * @param {ProjectNormalTypeCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function createProjectNormalType(data: ProjectNormalTypeCreateOrUpdate) {
  return request.post<ProjectNormalTypeItem, RequestResult<null>>(`/api/CaseType/create`, data)
}

/**
 * 编辑案件类型
 * @param {ProjectNormalTypeCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function updateProjectNormalType(data: ProjectNormalTypeCreateOrUpdate) {
  return request.put<ProjectNormalTypeItem, RequestResult<null>>(`/api/CaseType/update`, data)
}

/**
 * 删除案件类型
 * @param {ProjectNormalTypeItem} data
 * @returns {RequestResult}
 */
export async function deleteProjectNormalType(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/CaseType/delete/${id}`)
}

/**
 * 软删除案件类型
 * @param {ProjectNormalTypeItem} data
 * @returns {RequestResult}
 */
export async function deleteProjectNormalTypeSoft(id: number) {
  return request.post<number, RequestResult<null>>(`/api/CaseType/SoftDelete/${id}`)
}
