import { defineStore } from 'pinia'
import { getLawfirmParams } from '@/api/app'
import { AppLawfirmParams } from './typing'
import { getUserConfig } from '@/api/user/user'
import { UserConfigItem } from '../user/typing'

export const AppFunction = defineStore('AppFunction', {
  state: () => {
    return {
      params: {},
      TeamParams: {}
    } as {
      params: AppLawfirmParams
      TeamParams: UserConfigItem
    }
  },
  getters: {
    contractPayable: state => state.params?.isEnabledContractPayable || false,
    contractTravel: state => state.params?.isEnabledContractTravelAmount || false,
    paymentType: state => state.params?.h5Pay,
    letterESing: state => state.params?.isEnabledESign || false,
    UserParams: state => state.TeamParams
  },
  actions: {
    async appLawfirmParams(lawfirmId: number) {
      try {
        const res = await getLawfirmParams(lawfirmId)
        const { data, succeeded } = res
        succeeded ? (this.params = data) : ''
        return res
      } catch (error) {}
    },

    async appUserParams() {
      try {
        const res = await getUserConfig()
        const { data, succeeded } = res
        succeeded ? (this.TeamParams = data.teamConfig) : ''
        return res
      } catch (error) {}
    }
  }
})
