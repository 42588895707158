import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

export const Letter: RouteRecordRaw = {
  path: 'letterproject',
  name: 'manage-letterproject',
  meta: { title: '函书管理(项目)', role: 'Admin.Letter', icon: 'oa_letter', fullPath: '/manage/letterproject' },
  component: RouteView,
  redirect: '/manage/letterproject/list',
  children: [
    {
      path: 'enterlist',
      name: 'manage-letterproject-enter-list',
      meta: { icon: 'oa_letter', role: 'Admin.Letter.Create', title: '函书登记', fullPath: '/manage/letterproject/enterlist' },
      component: (): Component => import('@/views/manage/projectLetter/enter.vue')
    },
    {
      path: 'guide/:projectId(\\d+)/:caseTypeId(\\d+)/:projectTypeCode',
      name: 'manage-letterproject-guide',
      meta: { icon: 'oa_letter', role: 'Admin.Letter.Create', title: '函书申领', hideInMenu: true, fullPath: '/manage/letterproject/enter/guide' },
      component: (): Component => import('@/views/projectLetter/guide.vue')
    },
    {
      path: 'apply/:letterType/:projectId(\\d+)/:projectTypeCode/:region?',
      name: 'manage-letterproject-apply',
      meta: { icon: 'oa_letter', role: 'Admin.Letter.Create', hideInMenu: true, title: '函书申领', fullPath: '/manage/letterproject/apply' },
      component: (): Component => import('@/views/projectLetter/apply.vue')
    },
    {
      path: 'list',
      name: 'manage-letterproject-list',
      meta: { icon: 'oa_letter', role: 'Admin.Letter.Pass', title: '函书审核', fullPath: '/manage/letterproject/list' },
      component: (): Component => import('@/views/manage/projectLetter/index.vue')
    },
    {
      path: 'pass/:letterId(\\d+)/:projectId(\\d+)/:projectTypeCode/:letterType',
      props: true,
      name: 'manage-letterproject-pass',
      meta: { icon: 'oa_letter', role: 'Admin.Letter.Pass', hideInMenu: true, title: '函书审核', fullPath: '/manage/letterproject/pass' },
      component: (): Component => import('@/views/manage/projectLetter/pass.vue')
    },
    {
      path: 'info/:projectId(\\d+)',
      props: true,
      name: 'manage-letterproject-info',
      meta: { icon: 'oa_letter', role: 'Admin.Letter', hideInMenu: true, title: '函书详情', fullPath: '/manage/letterproject/info' },
      component: (): Component => import('@/views/manage/projectLetter/info.vue')
    },
    {
      path: 'update/:letterType/:projectId(\\d+)/:projectTypeCode/:letterId?',
      name: 'manage-letterproject-apply-update',
      meta: { icon: 'oa_letter', role: 'Admin.Letter.Update', hideInMenu: true, title: '函书编辑', fullPath: '/manage/letterproject/update' },
      component: (): Component => import('@/views/projectLetter/apply.vue')
    }
  ]
}
