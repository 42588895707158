import { Component, defineAsyncComponent, h } from 'vue'
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'
import ManageLayout from '@/layouts/manage.vue'
import UserLayout from '@/layouts/user.vue'

// outSideRouter
import { OutSideUse } from './modules/other'

// userRoyuter
import { Demo } from './modules/demo'
import { Client } from './modules/client'
import { Market } from './modules/market'
import { Finance } from './modules/finance'
import { Finance as ProjectFinance } from './modules/projectFinance'
import { Case as UserCase } from './modules/case'
import { Project as UserProject } from './modules/project'
import { Bid as UserBid } from './modules/bid'
import { Letter as UserLetter } from './modules/letter'
import { Letter as UserProjectLetter } from './modules/projectLetter'
import { Administration as UserAdministration } from './modules/administration'
import { Account, MyAccount } from './modules/account'
import { Error, Result } from './modules/other'
import { Knowlege as Userknowledge } from './modules/knowlege'

// manageRouter
import { Case as ManageCase } from './modules/manage/case'
import { Project as ManageProject } from './modules/manage/project'
import { Bid as ManageBid } from './modules/manage/bid'
import { Finance as ManageFinance } from './modules/manage/finance'
import { Finance as ManageProjectFinance } from './modules/manage/projectFinance'
import { User } from './modules/manage/user'
import { Letter as ManageLetter } from './modules/manage/letter'
import { Letter as ManageProjectLetter } from './modules/manage/projectLetter'
import { Administration as ManageAdministration } from './modules/manage/administration'
import { System } from './modules/manage/system'
import { Statistics } from './modules/manage/statistics'
import { Complaint } from './modules/manage/complaint'
import { Knowlege as ManageKnowlege } from './modules/manage/knowlege'

// roleRouter
import { Role } from './modules/role'

const AsyncWorkplace = defineAsyncComponent(() => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/workplace/index.vue'))

/**
 * 管理路由
 */
export const ManageRoute: RouteRecordRaw = {
  path: '/manage',
  name: 'manage',
  redirect: '/manage/index',
  meta: { icon: 'oa_mokuai', title: '管理端工作台', fullPath: '/manage' },
  component: ManageLayout,
  children: [
    {
      path: 'index',
      name: 'manage-index',
      meta: { icon: 'oa_mokuai', role: 'Admin', title: '管理端工作台', lock: true, fullPath: '/manage/index' },
      component: h(RouteView, {}, () => h(AsyncWorkplace))
    },
    {
      path: 'notification',
      name: 'manage-notification',
      meta: { icon: 'oa_notification', title: '消息提醒', hideInMenu: true, fullPath: '/manage/notification' },
      component: (): Component => import('@/views/notification/notification.vue')
    },
    ManageCase,
    ManageProject,
    ManageLetter,
    ManageProjectLetter,
    ManageBid,
    ManageFinance,
    ManageProjectFinance,
    Complaint,
    User,
    ManageAdministration,
    ManageKnowlege,
    Statistics,
    System,
    Role,
    {
      path: '/managehelp',
      name: 'managehelp',
      redirect: 'http://doc.jingshonline.net',
      meta: { icon: 'oa_help', target: '_blank', title: '帮助中心', fullPath: 'http://doc.jingshonline.net/oa/manage.html' }
    },
    {
      path: '/touser',
      name: 'touser',
      redirect: '/',
      meta: { icon: 'oa_user_change', title: '用户中心', fullPath: '/' }
    }
  ]
}

/**
 * 用户路由
 */
export const UserRoute: RouteRecordRaw = {
  path: '/',
  name: '/',
  redirect: '/index',
  meta: { icon: 'oa_home', title: '工作台', fullPath: '/' },
  component: UserLayout,
  children: [
    {
      path: 'index',
      name: 'index',
      meta: { icon: 'oa_home', title: '工作台', lock: true, fullPath: '/index' },
      component: h(RouteView, {}, () => h(AsyncWorkplace))
    },
    {
      path: 'notification',
      name: 'notification',
      meta: { icon: 'oa_notification', title: '消息提醒', hideInMenu: true, fullPath: '/notification' },
      component: (): Component => import('@/views/notification/notification.vue')
    },
    Client,
    {
      path: '/tomarket',
      name: 'tomarket',
      redirect: '/market/index',
      meta: { icon: 'oa_xiansuo', title: '市场管理', fullPath: '/market' }
    },
    Market,
    UserProject,
    UserCase,
    UserBid,
    UserLetter,
    UserProjectLetter,
    ProjectFinance,
    Finance,
    UserAdministration,
    Userknowledge,
    MyAccount,
    {
      path: '/teamsite',
      name: 'teamsite',
      meta: { icon: 'oa_team', title: '团队权限配置', fullPath: '/teamsite' },
      component: (): Component => import('@/views/teamsite/index.vue')
    },
    {
      path: '/userhelp',
      name: 'userhelp',
      redirect: 'http://doc.jingshonline.net',
      meta: { icon: 'oa_help', target: '_blank', title: '帮助中心', fullPath: 'http://doc.jingshonline.net/oa/user.html' }
    },
    {
      path: '/tomanage',
      name: 'tomanage',
      redirect: '/manage',
      meta: { icon: 'oa_mokuai', role: 'Admin', title: '管理中心', fullPath: '/manage' }
    }
  ]
}

/**
 * 总路由
 */
export const routes: Array<RouteRecordRaw> = [ManageRoute, UserRoute]

/**
 * 静态路由
 */
export const staticRoutes: Array<RouteRecordRaw> = [
  Demo,
  OutSideUse,
  Account,
  Error,
  Result,
  {
    path: '/install',
    name: 'install',
    meta: { icon: 'oa_xiansuo', title: '项目初始化', hideInMenu: true, fullPath: '/install' },
    component: (): Component => import('@/views/init/index.vue')
  },
  {
    path: '/:pathMatch(.*)',
    meta: { icon: 'HistoryOutlined', title: '(*)', hideInMenu: true, fullPath: '/:pathMatch(.*)' },
    component: () => import(/* webpackChunkName: "exception" */ '@/views/error/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_APP_PUBLIC_PATH as string),
  routes: staticRoutes
})

export default router
