import { defineStore } from 'pinia'

import { checkProject, getProjectInfo, getProjectInfoForApplayEnd, udpateProjectRelevant } from '@/api/project'

import type { TableColumnsType } from 'ant-design-vue'
import type { ProjectClientItem } from '@/types/projectClient'
import type {
  ProjectLawyerType,
  ProjectStatus,
  ProjectEndResutlStatus,
  ProjectConfidentialStatus,
  ProjectType,
  ProjectInfo,
  ProjectCheckParams,
  ProjectUpdateRelationCache,
  ProjectInfoApplayEnd
} from '@/types/project'

/**
 * 项目类型
 */
export enum ProjectTypeCode {
  Normal = 0, // 一般案件项目
  Bid = 1, // 招投标项目
  Bankruptcy = 2, // 破产管理人项目
  Customer = 3, // 客服服务入库项目
  Risk = 4, // 风控内核项目
  Director = 5 // 独董备案项目
}

/**
 * 项目办案团队成员类型
 */
export enum ProjectLawyerTypeCode {
  Partner = 0, //团队负责人
  User = 1, // 主办律师
  Lawyer = 2, //协办律师
  Assistant = 3, //助理
  Service = 4, //客服人员
  Source = 5 //安源提供人
}

/**
 * 项目状态
 */
export enum ProjectStatusCode {
  Init = 0, //初始状态

  Parter = 100, // 等待合伙人审批

  ApplyPass = 200, //提交风控审批

  Passed = 300, //已审批待盖章

  Signed = 400, //已盖章办案中

  ApplyEnd = 500, //申请结案

  Ended = 600, //已结案待归档

  Archived = 700, //已结案已归档

  Leave = 800, //四方协议转出

  Rescission = 900 //合同解除
}

/**
 * 项目办理结果
 */
export enum ProjectEndResutlCode {
  Mediate = 386, //调解
  Judgment = 387, //判决
  Withdraw = 388, //撤诉
  Clear = 389, //清算
  Reconciliation = 390, //和解
  Reorganization = 391, //重整
  Lifted = 392, //解除
  Ruling = 393, //裁决
  Complete = 394, //完毕
  Arbitration = 395, //仲裁
  FinalJudgment = 396 //终审判决
}

/**
 * 项目涉密属性
 */
export enum ProjectConfidentialCode {
  Ordinary = 0, //普通案件
  WithStateSecret = 1, // 涉国家秘密
  WithTradeSecret = 2, //涉商业秘密
  WithPersonalPrivacy = 3 //涉个人隐私
}

export const Project = defineStore('Project', {
  state: () => {
    return {
      type: {
        [ProjectTypeCode.Normal]: { key: ProjectTypeCode.Normal, title: '一般案件项目' },
        [ProjectTypeCode.Bid]: { key: ProjectTypeCode.Bid, title: '招投标项目' },
        [ProjectTypeCode.Bankruptcy]: { key: ProjectTypeCode.Bankruptcy, title: '破产管理人项目' },
        [ProjectTypeCode.Customer]: { key: ProjectTypeCode.Customer, title: '客户服务入库项目' },
        [ProjectTypeCode.Risk]: { key: ProjectTypeCode.Risk, title: '风控内核项目' },
        [ProjectTypeCode.Director]: { key: ProjectTypeCode.Director, title: '独董备案项目' }
      } as ProjectType,
      lawyerType: {
        [ProjectLawyerTypeCode.Partner]: { key: ProjectLawyerTypeCode.Partner, title: '团队负责人' },
        [ProjectLawyerTypeCode.Lawyer]: { key: ProjectLawyerTypeCode.Lawyer, title: '办案律师' },
        [ProjectLawyerTypeCode.Assistant]: { key: ProjectLawyerTypeCode.Assistant, title: '办案助理' },
        [ProjectLawyerTypeCode.Service]: { key: ProjectLawyerTypeCode.Service, title: '客服人员' },
        [ProjectLawyerTypeCode.Source]: { key: ProjectLawyerTypeCode.Source, title: '案源人' },
        [ProjectLawyerTypeCode.User]: { key: ProjectLawyerTypeCode.User, title: '案件负责人' }
      } as ProjectLawyerType,
      status: {
        [ProjectStatusCode.Init]: { key: ProjectStatusCode.Init, title: '初始状态', color: 'success' },
        [ProjectStatusCode.Parter]: { key: ProjectStatusCode.Parter, title: '等待合伙人审批', color: 'success' },
        [ProjectStatusCode.ApplyPass]: { key: ProjectStatusCode.ApplyPass, title: '提交风控审批', color: 'success' },
        [ProjectStatusCode.Passed]: { key: ProjectStatusCode.Passed, title: '已审批待盖章', color: 'success' },
        [ProjectStatusCode.Signed]: { key: ProjectStatusCode.Signed, title: '已盖章办案中', color: 'success' },
        [ProjectStatusCode.ApplyEnd]: { key: ProjectStatusCode.ApplyEnd, title: '申请结案', color: 'success' },
        [ProjectStatusCode.Ended]: { key: ProjectStatusCode.Ended, title: '已结案待归档', color: 'success' },
        [ProjectStatusCode.Archived]: { key: ProjectStatusCode.Archived, title: '已结案已归档', color: 'success' },
        [ProjectStatusCode.Leave]: { key: ProjectStatusCode.Leave, title: '四方协议转出', color: 'success' },
        [ProjectStatusCode.Rescission]: { key: ProjectStatusCode.Rescission, title: '合同解除', color: 'success' }
      } as ProjectStatus,
      endReasult: {
        [ProjectEndResutlCode.Mediate]: { key: ProjectEndResutlCode.Mediate, title: '调解' },
        [ProjectEndResutlCode.Judgment]: { key: ProjectEndResutlCode.Judgment, title: '判决' },
        [ProjectEndResutlCode.Withdraw]: { key: ProjectEndResutlCode.Withdraw, title: '撤诉' },
        [ProjectEndResutlCode.Clear]: { key: ProjectEndResutlCode.Clear, title: '清算' },
        [ProjectEndResutlCode.Reconciliation]: { key: ProjectEndResutlCode.Reconciliation, title: '和解' },
        [ProjectEndResutlCode.Reorganization]: { key: ProjectEndResutlCode.Reorganization, title: '重整' },
        [ProjectEndResutlCode.Lifted]: { key: ProjectEndResutlCode.Lifted, title: '解除' },
        [ProjectEndResutlCode.Ruling]: { key: ProjectEndResutlCode.Ruling, title: '裁决' },
        [ProjectEndResutlCode.Complete]: { key: ProjectEndResutlCode.Complete, title: '完毕' },
        [ProjectEndResutlCode.Arbitration]: { key: ProjectEndResutlCode.Arbitration, title: '仲裁' },
        [ProjectEndResutlCode.FinalJudgment]: { key: ProjectEndResutlCode.FinalJudgment, title: '终审判决' }
      } as ProjectEndResutlStatus,
      confidential: {
        [ProjectConfidentialCode.Ordinary]: { key: ProjectConfidentialCode.Ordinary, title: '普通案件' },
        [ProjectConfidentialCode.WithStateSecret]: { key: ProjectConfidentialCode.WithStateSecret, title: '涉国家秘密' },
        [ProjectConfidentialCode.WithTradeSecret]: { key: ProjectConfidentialCode.WithTradeSecret, title: '涉商业秘密' },
        [ProjectConfidentialCode.WithPersonalPrivacy]: { key: ProjectConfidentialCode.WithPersonalPrivacy, title: '涉个人隐私' }
      } as ProjectConfidentialStatus,
      columns: [
        // 案件列表 列
        // key 用于判断列展示,可以避免 不同列表 相同列数据返回不一致  重复定义
        { key: 'operation', title: '操作', dataIndex: 'operation', exportHide: true, columnsDisabled: true, columnsSelected: true, fixed: 'left', width: 100 },
        { key: 'id', title: '流水号', dataIndex: 'id', fixed: 'left', width: 100, exportSelected: true, exportDisabled: true, columnsDisabled: true, columnsSelected: true },
        { key: 'lawfirmName', title: '律所', dataIndex: 'lawfirmName', fixed: 'left', width: 100, exportSelected: true, exportDisabled: true, columnsDisabled: true, columnsSelected: true },
        { key: 'realName', title: '项目负责人', dataIndex: 'realName', fixed: 'left', width: 110, exportSelected: true, exportDisabled: true, columnsDisabled: true, columnsSelected: true },
        { key: 'projectType', title: '项目类型', dataIndex: 'projectType', fixed: 'left', width: 150, exportSelected: true, exportDisabled: true, columnsDisabled: true, columnsSelected: true },
        {
          key: 'projectClients',
          title: '委托人',
          dataIndex: 'projectClients',
          fixed: 'left',
          width: 300,
          minWidth: 300,
          exportSelected: true,
          exportDisabled: true,
          columnsDisabled: true,
          columnsSelected: true
        },
        { key: 'projectStatus', title: '进展状态', dataIndex: 'projectStatus', columnsDisabled: true, columnsSelected: true, width: 200 },
        { key: 'amount', title: '合同金额', dataIndex: 'amount', width: 110, columnsSelected: true },
        { key: 'invoiceAmount', title: '开票金额', dataIndex: 'invoiceMoney', width: 110, columnsSelected: true },
        { key: 'paymentAmount', title: '缴费金额', dataIndex: 'paymentMoney', width: 110, columnsSelected: true },
        { key: 'projectSourceName', title: '项目渠道', dataIndex: 'projectSourceName', width: 120, columnsSelected: true },
        { key: 'caseTypeFullName', title: '案件类型', dataIndex: 'caseTypeFullName', width: 190, exportSelected: true, exportDisabled: true, columnsSelected: true },
        { key: 'creationTime', title: '项目录入时间', dataIndex: 'creationTime', width: 160, columnsDisabled: true, columnsSelected: true },
        { key: 'industryFullName', title: '行业', dataIndex: 'industryFullName', width: 300, columnsSelected: true },
        { key: 'businessAreaFullName', title: '领域', dataIndex: 'businessAreaFullName', width: 300, columnsSelected: true },
        { key: 'caseReasonFullName', title: '案由', dataIndex: 'caseReasonFullName', width: 300, columnsSelected: true },
        { key: 'name', title: '项目名称', dataIndex: 'name', width: 300 },
        { key: 'projectNumber', title: '项目编号', dataIndex: 'projectNumber', width: 300, exportSelected: true, exportDisabled: true, columnsSelected: true },
        { key: 'beginDate', title: '项目开始时间', dataIndex: 'beginDate', width: 100 },
        { key: 'endDate', title: '项目结束时间', dataIndex: 'endDate', width: 100 }
      ] as Array<TableColumnsType>,
      invoiceColumns: [
        { key: 'invoiceType', title: '发票类型', dataIndex: 'invoiceType' },
        { key: 'amount', title: '开票金额(元)', dataIndex: 'amount' },
        { key: 'invoiceDate', title: '开票时间', dataIndex: 'invoiceDate' },
        { key: 'clientName', title: '名称', dataIndex: 'clientName' },
        { key: 'taxNumber', title: '纳税人识别号', dataIndex: 'taxNumber' },
        { key: 'isCompleted', title: '状态', dataIndex: 'isCompleted' },
        { key: 'isInvalid', title: '是否作废', dataIndex: 'isInvalid' },
        { key: 'address', title: '地址', dataIndex: 'address' },
        { key: 'tel', title: '电话', dataIndex: 'tel' },
        { key: 'bank', title: '开户行', dataIndex: 'bank' },
        { key: 'bankAccount', title: '银行账号', dataIndex: 'bankAccount' },
        { key: 'remarks', title: '备注', dataIndex: 'remarks' },
        { key: 'files', title: '附件', dataIndex: 'files' }
      ] as Array<TableColumnsType>,
      paymentColumns: [
        { key: 'isCompleted', title: '状态', dataIndex: 'isCompleted' },
        { key: 'paymentTime', title: '缴费时间', dataIndex: 'paymentTime' },
        { key: 'amount', title: '缴费金额(元)', dataIndex: 'amount' },
        { key: 'paymentChannelName', title: '缴费方式', dataIndex: 'paymentChannelName' },
        { key: 'bailorName', title: '客户名称', dataIndex: 'bailorName' },
        { key: 'agent', title: '代付人', dataIndex: 'agent' },
        { key: 'payBank', title: '缴费银行名称', dataIndex: 'payBank' },
        { key: 'payBankAccount', title: '缴费账号', dataIndex: 'payBankAccount' },
        { key: 'remarks', title: '备注', dataIndex: 'remarks' },
        { key: 'files', title: '附件', dataIndex: 'files' }
      ] as Array<TableColumnsType>,
      refundColumns: [
        { key: 'ID', title: 'ID', dataIndex: 'id' },
        { key: 'isCompleted', title: '状态', dataIndex: 'isCompleted' },
        { key: 'amount', title: '退票金额(元)', dataIndex: 'amount' },
        { key: 'refundType', title: '退费方式', dataIndex: 'refundType' },
        { key: 'invoiceIds', title: '关联发票', dataIndex: 'invoiceIds' },
        { key: 'remarks', title: '备注', dataIndex: 'remarks' },
        { key: 'files', title: '附件', dataIndex: 'files' }
      ] as Array<TableColumnsType>,
      info: {
        id: 0,
        name: '',
        projectNumber: '',
        userId: 0,
        userName: '',
        userRealName: '',
        lawfirmId: 0,
        lawfirmName: '',
        lawfirmAddress: '',
        lawfirmLicenseNumber: '',

        projectType: ProjectTypeCode.Normal,

        projectStatus: ProjectStatusCode.Init,

        beginDate: ' ',
        endDate: '',
        caseTypeId: 0,
        caseTypeName: '',

        industryId: 0,
        industryName: '',

        businessAreaId: 0,
        businessAreaName: '',

        caseReasonId: 0,
        caseReasonName: '',
        caseReasonFullName: '',
        caseReasonRemarks: '',

        businessDepartmentId: 0,
        businessDepartmentName: '',

        projectSourceId: 0,
        projectSourceName: '',

        amount: 0,
        paymentAmount: 0,
        invoiceAmount: 0,
        hasRecordAmount: 0,

        isPublic: false,
        isConflict: false,
        creationTime: '',
        projectClients: [],

        dossierNumber: '',

        projectOrganizations: [],
        projectFinance: {
          projectId: 0,
          paymentMethodId: 0,
          paymentMethodFullName: '',
          riskRatio: 0,
          riskMoney: 0,
          riskRemarks: '',
          targetAmount: 0,
          travelAmount: 0,
          targetInfo: '',
          paymentRemarks: '',
          projectPayableList: []
        },
        normalProject: {
          projectId: 0,
          trialLevelList: '',
          currentTrialLevel: '',
          isPublicSuit: false,
          clientRole: '',
          isRiskAgency: false,
          isLegalAid: false,
          isSensitive: false,
          isForeign: false,
          isGangs: false,
          isGroupment: false,
          isWithCivil: false,
          clientAndCrimerRelationship: '',
          clientAndCrimerRelationshipRemarks: ''
        },
        bidProject: {
          source: 0,
          trialLevelList: ''
        },
        bankruptcyProject: null,
        customerProject: null,
        riskProject: null,
        directorProject: null,
        excerpt: '',
        remarks: ''
      } as ProjectInfo,
      applayEndInfo: {
        projectId: 0,
        projectResult: undefined,
        projectSummary: {
          introduction: '',
          thinking: '',
          process: '',
          result: ''
        },
        remarks: '',
        isSucceed: undefined,
        intro: '',
        files: []
      } as ProjectInfoApplayEnd
    }
  },
  getters: {
    projectType: state => state.type as ProjectType, // 项目类型
    projectLawyerType: state => state.lawyerType as ProjectLawyerType, // 办案团队成员类别
    projectConfidential: state => state.confidential as ProjectConfidentialStatus, // 项目涉密属性
    projectEndResult: state => state.endReasult as ProjectEndResutlStatus, // 项目办理结果
    projectStatus: state => state.status as ProjectStatus, // 项目状态
    projectColumns: state => state.columns as Array<TableColumnsType>, // 列表列配置
    projectInfoInvoiceColumns: state => state.invoiceColumns as Array<TableColumnsType>, // 详情发票列表列配置
    projectInfoPaymentColumns: state => state.paymentColumns as Array<TableColumnsType>, // 详情缴费列表列配置
    projectInfoRefundColumns: state => state.refundColumns as Array<TableColumnsType>, // 详情退票列表列配置
    projectInfo: state => state.info as ProjectInfo, //项目详情
    projectApplayEndInfo: state => state.applayEndInfo as ProjectInfoApplayEnd // 项目申请结案信息
  },
  actions: {
    // 获取项目详情
    async getInfo(projectId: number) {
      const res = await getProjectInfo(projectId)
      const { data, succeeded } = res
      succeeded ? (this.info = data) : ''
    },
    async getApplayInfo(projectId: number) {
      const res = await getProjectInfoForApplayEnd(projectId)
      const { data, succeeded } = res
      succeeded ? (this.applayEndInfo = data) : ''
    },
    async checkConflict(checkData: ProjectCheckParams) {
      const res = await checkProject(checkData)
      return res
    },
    async updateRelevant(data: ProjectUpdateRelationCache) {
      const clientList: Array<ProjectClientItem> = []
      data.relations.forEach(client => {
        client.clientList?.length ? clientList.push(...client.clientList) : ''
      })
      const res = await udpateProjectRelevant({ id: data.id, relations: clientList })
      return res
    }
  }
})
