import { defineStore } from 'pinia'
import { TableColumnsType } from 'ant-design-vue'

import { ProjectClientType, ProjectClientIdCardType, ProjectClientProperty } from '@/types/projectClient'
import { checkIDCardNo, checkCreditCode, checkPassport, checkHongKong, checkTaiwan } from '@/utils'
import { ProjectTypeCode } from '../project'

/**
 * 项目客户类型
 */
export enum ProjectClientTypeCode {
  Client = 0, //委托人
  Privies = 1, //利益相对方
  ThirdParty = 2, //第三方
  ExclusionParty = 3, //合同约定的特别排除方
  Crimer = 4, //犯罪嫌疑人
  Claiment = 5, //申请人(破产管理人项目)
  Respondent = 6, //被申请人(破产管理人项目)
  Creditor = 7, //债权人 (破产管理人项目)
  Debtor = 8 //债务人 (破产管理人项目)
  // Unit = 5, //入库单位（名称）
  // Tenderee = 6,  //招标方
}

/**
 * 项目客户属性
 */
export enum ProjectClientPropertyCode {
  Person = 1, // 个人
  Organization = 2, // 企业
  Government = 3 // 政府
}

/**
 * 项目客户证件类型
 */
export enum ProjectCliectIdCardTypeCode {
  Id = 1, //身份证
  CreditCode = 2, // 社会统一信用代码
  PassPort = 3, // 护照
  PassCheck = 4, // 港澳台通行证
  TaiwanCertificate = 5, //台胞证
  Other = 6
}

export const ProjectClient = defineStore('ProjectClient', {
  state: () => {
    return {
      clientType: {
        [ProjectClientTypeCode.Client]: {
          key: ProjectClientTypeCode.Client,
          title: {
            [ProjectTypeCode.Normal]: '委托人',
            [ProjectTypeCode.Bid]: '招标单位',
            [ProjectTypeCode.Customer]: '入库单位',
            [ProjectTypeCode.Bankruptcy]: '委托人',
            [ProjectTypeCode.Director]: '委托人',
            [ProjectTypeCode.Risk]: '委托人'
          },
          projectType: [ProjectTypeCode.Normal, ProjectTypeCode.Bid, ProjectTypeCode.Bankruptcy, ProjectTypeCode.Customer, ProjectTypeCode.Director, ProjectTypeCode.Risk]
        },
        [ProjectClientTypeCode.Privies]: {
          key: ProjectClientTypeCode.Privies,
          title: {
            [ProjectTypeCode.Normal]: '利益相对方',
            [ProjectTypeCode.Bid]: '利益相对方',
            [ProjectTypeCode.Customer]: '其他利益相对方',
            [ProjectTypeCode.Bankruptcy]: '其他利益相对方',
            [ProjectTypeCode.Director]: '利益相对方',
            [ProjectTypeCode.Risk]: '利益相对方'
          },
          projectType: [ProjectTypeCode.Normal]
        },
        [ProjectClientTypeCode.ThirdParty]: {
          key: ProjectClientTypeCode.ThirdParty,
          title: {
            [ProjectTypeCode.Normal]: '第三方',
            [ProjectTypeCode.Bid]: '第三方',
            [ProjectTypeCode.Customer]: '第三方',
            [ProjectTypeCode.Bankruptcy]: '第三方',
            [ProjectTypeCode.Director]: '第三方',
            [ProjectTypeCode.Risk]: '第三方'
          },
          projectType: [ProjectTypeCode.Normal]
        },
        [ProjectClientTypeCode.ExclusionParty]: {
          key: ProjectClientTypeCode.ExclusionParty,
          title: {
            [ProjectTypeCode.Normal]: '合同约定的特别排除方',
            [ProjectTypeCode.Bid]: '合同约定的特别排除方',
            [ProjectTypeCode.Customer]: '合同约定的特别排除方',
            [ProjectTypeCode.Bankruptcy]: '合同约定的特别排除方',
            [ProjectTypeCode.Director]: '合同约定的特别排除方',
            [ProjectTypeCode.Risk]: '合同约定的特别排除方'
          },
          projectType: [ProjectTypeCode.Normal]
        },
        [ProjectClientTypeCode.Crimer]: {
          key: ProjectClientTypeCode.Crimer,
          title: {
            [ProjectTypeCode.Normal]: '犯罪嫌疑人',
            [ProjectTypeCode.Bid]: '犯罪嫌疑人',
            [ProjectTypeCode.Customer]: '犯罪嫌疑人',
            [ProjectTypeCode.Bankruptcy]: '犯罪嫌疑人',
            [ProjectTypeCode.Director]: '犯罪嫌疑人',
            [ProjectTypeCode.Risk]: '犯罪嫌疑人'
          },
          projectType: [ProjectTypeCode.Normal]
        },
        [ProjectClientTypeCode.Claiment]: {
          key: ProjectClientTypeCode.Claiment,
          title: {
            [ProjectTypeCode.Normal]: '申请人',
            [ProjectTypeCode.Bid]: '申请人',
            [ProjectTypeCode.Customer]: '申请人',
            [ProjectTypeCode.Bankruptcy]: '申请人',
            [ProjectTypeCode.Director]: '申请人',
            [ProjectTypeCode.Risk]: '申请人'
          },
          projectType: [ProjectTypeCode.Bankruptcy]
        },
        [ProjectClientTypeCode.Respondent]: {
          key: ProjectClientTypeCode.Respondent,
          title: {
            [ProjectTypeCode.Normal]: '被申请人',
            [ProjectTypeCode.Bid]: '被申请人',
            [ProjectTypeCode.Customer]: '被申请人',
            [ProjectTypeCode.Bankruptcy]: '被申请人',
            [ProjectTypeCode.Director]: '被申请人',
            [ProjectTypeCode.Risk]: '被申请人'
          },
          projectType: [ProjectTypeCode.Bankruptcy]
        },
        [ProjectClientTypeCode.Creditor]: {
          key: ProjectClientTypeCode.Creditor,
          title: {
            [ProjectTypeCode.Normal]: '债权人',
            [ProjectTypeCode.Bid]: '债权人',
            [ProjectTypeCode.Customer]: '债权人',
            [ProjectTypeCode.Bankruptcy]: '债权人',
            [ProjectTypeCode.Director]: '债权人',
            [ProjectTypeCode.Risk]: '债权人'
          },
          projectType: [ProjectTypeCode.Bankruptcy]
        },
        [ProjectClientTypeCode.Debtor]: {
          key: ProjectClientTypeCode.Debtor,
          title: {
            [ProjectTypeCode.Normal]: '债务人',
            [ProjectTypeCode.Bid]: '债务人',
            [ProjectTypeCode.Customer]: '债务人',
            [ProjectTypeCode.Bankruptcy]: '债务人',
            [ProjectTypeCode.Director]: '债务人',
            [ProjectTypeCode.Risk]: '债务人'
          },
          projectType: [ProjectTypeCode.Bankruptcy]
        }
        // [ProjectCLientTypeCode.Unit]: { key: ProjectCLientTypeCode.Unit, title: '入库单位' },
        // [ProjectCLientTypeCode.Tenderee]: { key: ProjectCLientTypeCode.Tenderee, title: '招标方' }
      } as ProjectClientType,
      property: {
        [ProjectClientPropertyCode.Person]: {
          key: ProjectClientPropertyCode.Person,
          title: '个人',
          idCardType: [ProjectCliectIdCardTypeCode.Id, ProjectCliectIdCardTypeCode.PassPort, ProjectCliectIdCardTypeCode.PassCheck, ProjectCliectIdCardTypeCode.TaiwanCertificate]
        },
        [ProjectClientPropertyCode.Organization]: {
          key: ProjectClientPropertyCode.Organization,
          title: '企业',
          idCardType: [ProjectCliectIdCardTypeCode.CreditCode, ProjectCliectIdCardTypeCode.Other]
        },
        [ProjectClientPropertyCode.Government]: { key: ProjectClientPropertyCode.Government, title: '政府', idCardType: [ProjectCliectIdCardTypeCode.CreditCode, ProjectCliectIdCardTypeCode.Other] }
      } as ProjectClientProperty,
      idCardType: {
        [ProjectCliectIdCardTypeCode.Id]: { key: ProjectCliectIdCardTypeCode.Id, title: '身份证', clientProperty: [ProjectClientPropertyCode.Person] },
        [ProjectCliectIdCardTypeCode.CreditCode]: {
          key: ProjectCliectIdCardTypeCode.CreditCode,
          title: '统一社会信用代码',
          clientProperty: [ProjectClientPropertyCode.Organization, ProjectClientPropertyCode.Government]
        },
        [ProjectCliectIdCardTypeCode.PassPort]: { key: ProjectCliectIdCardTypeCode.PassPort, title: '护照', clientProperty: [ProjectClientPropertyCode.Person] },
        [ProjectCliectIdCardTypeCode.PassCheck]: { key: ProjectCliectIdCardTypeCode.PassCheck, title: '港澳通行证', clientProperty: [ProjectClientPropertyCode.Person] },
        [ProjectCliectIdCardTypeCode.TaiwanCertificate]: { key: ProjectCliectIdCardTypeCode.TaiwanCertificate, title: '台胞证', clientProperty: [ProjectClientPropertyCode.Person] },
        [ProjectCliectIdCardTypeCode.Other]: { key: ProjectCliectIdCardTypeCode.Other, title: '其他', clientProperty: [ProjectClientPropertyCode.Organization, ProjectClientPropertyCode.Government] }
      } as ProjectClientIdCardType,
      columns: [
        { key: 'id', title: '编号', dataIndex: 'id', fixed: 'left', width: 100, exportSelected: true, exportDisabled: true, columnsDisabled: true, columnsSelected: true },
        { key: 'name', title: '名称', dataIndex: 'name', fixed: 'left', exportSelected: true, exportDisabled: true, columnsDisabled: true, columnsSelected: true },
        { key: 'oldName', title: '曾用名', dataIndex: 'oldName', columnsDisabled: true, columnsSelected: true },
        { key: 'isPublic', title: '公共客户', dataIndex: 'isPublic', width: 100 },
        { key: 'clientSourceName', title: '客户来源', dataIndex: 'clientSourceName', width: 150 },
        { key: 'clientType', title: '客户类型', dataIndex: 'clientType', width: 100 },
        { key: 'idCardType', title: '证件类型', dataIndex: 'idCardType', width: 180 },
        { key: 'idCardNumber', title: '证件号码', dataIndex: 'idCardNumber' },
        { key: 'industryName', title: '行业', dataIndex: 'industryName' },
        { key: 'regionFullName', title: '地区', dataIndex: 'regionFullName' },
        { key: 'address', title: '地址', dataIndex: 'address' },
        { key: 'operation', title: '操作', dataIndex: 'operation', fixed: 'right', width: 120, exportHide: true, columnsDisabled: true, columnsSelected: true }
      ] as Array<TableColumnsType>
    }
  },
  getters: {
    projectClientColums: state => state.columns as Array<TableColumnsType>,
    projectClientType: state => state.clientType as ProjectClientType, // 项目客户类型
    projectClientProperty: state => state.property as ProjectClientProperty,
    projectClientIdCardType: state => state.idCardType as ProjectClientIdCardType
  },
  actions: {
    getIdCardType(clientProperty: ProjectClientPropertyCode) {
      if (!clientProperty) return this.idCardType

      const Object = {} as ProjectClientIdCardType
      this.property[clientProperty].idCardType.forEach(item => {
        Object[item] = this.idCardType[item]
      })
      return Object
    },
    // 根据证件类型值检查格式是否正确
    checkCertificateFormat(value: string, type?: ProjectCliectIdCardTypeCode): boolean {
      switch (type) {
        case ProjectCliectIdCardTypeCode.Id:
          return checkIDCardNo(value)
        case ProjectCliectIdCardTypeCode.CreditCode:
          return checkCreditCode(value)
        case ProjectCliectIdCardTypeCode.PassPort:
          return checkPassport(value)
        case ProjectCliectIdCardTypeCode.PassCheck:
          return checkHongKong(value)
        case ProjectCliectIdCardTypeCode.TaiwanCertificate:
          return checkTaiwan(value)
        default:
          return true
      }
    },
    // 根据证件类型名称检查格式是否正确
    checkCertificateFormatForName(value: string, type?: string): boolean {
      switch (type) {
        case this.idCardType[ProjectCliectIdCardTypeCode.Id].title:
          return checkIDCardNo(value)
        case this.idCardType[ProjectCliectIdCardTypeCode.CreditCode].title:
          return checkCreditCode(value)
        case this.idCardType[ProjectCliectIdCardTypeCode.PassPort].title:
          return checkPassport(value)
        case this.idCardType[ProjectCliectIdCardTypeCode.PassCheck].title:
          return checkHongKong(value)
        case this.idCardType[ProjectCliectIdCardTypeCode.TaiwanCertificate].title:
          return checkTaiwan(value)
        default:
          return true
      }
    }
  }
})
